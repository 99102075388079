import React, { Fragment, useState, useEffect, useContext } from 'react'
// import { fire } from '../../../utils/firebaseSettings'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { getGameInstancesForTemplate } from '../../../utils/firebaseGameCreationUtils'
import { AuthContext } from '../../../data/context/AuthProvider'
import { CardDiv, Toggle } from '../../../components'

const GameCard = styled(CardDiv)`float: left; border: solid 1px gainsboro; height: 270px; border-radius: 6px; width: 95%; margin: 20px; text-align: center; `
const GameCardImageArea = styled.div`height: 150px; width: 100%;`
const GameCardImage = styled.img`height: 150px; max-width: 100%; border-radius: 6px 6px 0px 0px;`
const GameIDLink = styled(Link)`height: 30px; font-size: 16px; font-weight: bold; overflow: hidden;`
const SlideCount = styled.div`float: left; font-size: 18px; background-color: navy; color: white; height: 26px; width: 30px; font-weight: bold; border-radius: 30px;`
const CreateDate = styled.div`float: right; font-size: 12px;`
const OwnerEmail = styled.div`float: right; font-size: 12px;`

const LessonsList = styled.div`height: 200px; overflow: auto; border: solid 1px gainsboro; border-radius: 6px; margin: 5px;`

const tabs = [
	{ id: 'design', label: 'Tasarım' },
	{ id: 'lessons', label: 'Dersler' },
]

function GameTemplateListItem(props) {
    const [authData] = useContext(AuthContext)

    const [gameInstances, setGameInstances] = useState([])
    const [tab, setTab] = useState('design')

    useEffect(() => {
        if (tab === 'lessons' && (!gameInstances || gameInstances.length === 0 )) {
            getGameInstances()
        }
    }, [tab])

    const getGameInstances = () => {
        getGameInstancesForTemplate(props.gameTemplate.templateKey, (returnedGameInstanceList) => {
            console.log('game Instance List:', returnedGameInstanceList)
            returnedGameInstanceList.sort(function(a,b) {
                if (a.created < b.created) return 1
                if (a.created > b.created) return -1
                return 0
            })
            setGameInstances(returnedGameInstanceList)
        })
    }


    // function fetchMyGames(_callback) {        
    //     fire.database().ref('games')
    //     .once('value', _callback)
    // }
    
    // function getMyGames(_callback) {
    //     fetchMyGames((snapshot) => {
    //         let myGameList = []
    //         if (snapshot.val()) {			
    //             snapshot.forEach((child) => {
    //                 myGameList.push(child.val())
    //             })
    //         }
    //         return _callback(myGameList)
    //     })
    // }

    // const copyGame = () => {
    //     getMyGames((games) => {
    //         games.map((game) => {
                
    //             console.log('game: ', game)
    //             let tempGameTemplate = {...game}
    //             tempGameTemplate.gameType = 'quiz'
    //             tempGameTemplate.players = []
    //             console.log('tempGameTemplate:', tempGameTemplate)
    //             let gameTemplateRef = fire.database().ref(`/game_templates`).push()
    //             tempGameTemplate.key = gameTemplateRef.key
    //             tempGameTemplate.templateKey = gameTemplateRef.key
    //             fire.database().ref(`game_templates/${tempGameTemplate.key}`).update(tempGameTemplate, (error) => {
                    
    //             })


    //             let tempGameInstance = {...game}
    //             tempGameInstance.gameType = 'quiz'                
    //             tempGameInstance.templateKey = gameTemplateRef.key
    //             console.log('tempGameInstance: ', tempGameInstance)
    //             let gameInstanceRef = fire.database().ref(`/game_instances`).push()
    //             tempGameInstance.key = gameInstanceRef.key
    //             fire.database().ref(`game_instances/${tempGameInstance.key}`).update(tempGameInstance, (error) => {
                    
    //             })
    //         })
    //     })
    // }

    return (
        <GameCard key={props.gameTemplate.key}>
            <Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />

            {
                tab === 'design' &&
                    <div>
                        <GameCardImageArea>
                            <GameCardImage src={props.gameTemplate.quiz.questions && props.gameTemplate.quiz.questions.length > 0 ? props.gameTemplate.quiz.questions[0].questionImageURL : '' }  />
                        </GameCardImageArea>

                        <div>
                            <GameIDLink to={`/create-new-game/${props.gameTemplate.key}`}>{props.gameTemplate.title && props.gameTemplate.title.length > 0 ? props.gameTemplate.title : 'Isimsiz Ders'}</GameIDLink>
                        </div>
                        {/* <div onClick={() => copyGame()}>COPY</div> */}
                        
                        <SlideCount>{props.gameTemplate.quiz.questions ? props.gameTemplate.quiz.questions.length : 0}</SlideCount>
                        <CreateDate>{moment(props.gameTemplate.created).format('DD MMMM YYYY hh:mm')}</CreateDate>
                        
                        {
                            (authData.roles && authData.roles.some(role => ['owner', 'admin'].indexOf(role) >= 0 )) && 
                                <OwnerEmail>{props.gameTemplate.owner} # {props.gameTemplate.key}</OwnerEmail>
                        }
                    </div>
            }
            {
                tab === 'lessons' &&
                    <div>
                        <LessonsList>
                            <Grid container>
                                {
                                    gameInstances && gameInstances.length > 0 &&
                                        gameInstances.map((gameInstance) => gameInstance &&
                                            <Fragment key={gameInstance.key}>
                                                <Grid item xs={8}>
                                                    <div style={{textAlign: 'left'}}>
                                                        {
                                                            gameInstance.status === 'CREATED' ?
                                                                <a href={`/host-game/${gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
                                                                    <PlayCircleFilledIcon /> {gameInstance.title}
                                                                </a>
                                                            :
                                                                gameInstance.status === 'IN_PROGRESS' ?
                                                                    <a href={`/host-game/${gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
                                                                        <PlayCircleFilledIcon /> {gameInstance.title}
                                                                    </a>
                                                                    :        
                                                                    <a href={`/host-game/${gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
                                                                        <AssessmentIcon /> {gameInstance.title}
                                                                    </a>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <b style={{fontSize: '12px'}}>{gameInstance.gameId} ({gameInstance.players ?  Object.keys(gameInstance.players).length : 0})</b>
                                                </Grid>
                                                <Grid item xs={1}>
                                                </Grid>
                                            </Fragment>
                                        )
                                }
                            </Grid>
                        </LessonsList>
                    </div>
            }
        </GameCard>
	)
}

export default GameTemplateListItem
