import { fire } from './firebaseSettings'

function fetchGameInstance(gameId, _callback) {
	fire.database().ref('game_instances').orderByChild('gameId').equalTo(gameId)
		.once('value', _callback)
}

function getGameInstanceData(gameId, _callback) {
	fetchGameInstance(gameId, (snapshot) => {
		let game
		if (snapshot.val()) {			
			snapshot.forEach((child) => {
				game = child.val()
			})
		}
		return _callback(game)
	})
}

function getGameInstanceRef(gameKey) {
	let gameRef = fire.database().ref(`game_instances/${gameKey}`)
	return gameRef
}

function addGameInstanceAndReturnRef() {
	let gameRef = fire.database().ref(`/game_instances`).push()
	return gameRef
}

function getPlayerRef(gameKey, playerKey) {
	let playerRef = fire.database().ref(`/game_instances/${gameKey}/players/${playerKey}`)
	return playerRef
}

function addPlayerAndReturnRef(gameKey) {
	let playerRef = fire.database().ref(`/game_instances/${gameKey}/players`).push()
	return playerRef
}

function updateGameInstanceData(gameKey, gameUpdatedData, _callback) {
	fire.database().ref(`game_instances/${gameKey}`).update(gameUpdatedData, (error) => {
		_callback(error)
	})
}

function saveAnswerOnPlay(gameKey, playerKey, questionId, answer, _callback) {
	fire.database().ref('/game_instances/' + gameKey + '/players/' + playerKey + '/answers/' + questionId).set(answer, function (error) {
		_callback(error)
	})
}

function updateDrawingData (drawingData, gameKey, questionId) {
	fire.database().ref('/game_instances/' + gameKey + '/quiz/questions/' + parseInt(questionId - 1)).set(drawingData, function (error) { if (error) { } })
}

function clearDrawingData (drawingData, gameKey, questionId) {
	fire.database().ref('/game_instances/' + gameKey + '/quiz/questions/' + parseInt(questionId - 1)).set(drawingData, function (error) { if (error) { } })
}


export { getGameInstanceData, getGameInstanceRef, addGameInstanceAndReturnRef, getPlayerRef, addPlayerAndReturnRef, updateGameInstanceData, saveAnswerOnPlay,
	updateDrawingData, clearDrawingData
}
