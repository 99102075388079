import React, { Component } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AnswerOptionList from '../shared/AnswerOptionList'
import AnswerCounter from '../shared/AnswerCounter'
import QuestionContent from '../shared/QuestionContent'
import { Timer } from '../../../components'
import ReactPlayer from 'react-player/youtube'

const QuestionSection = styled.div`height: 54vh; text-align: center; overflow: auto; width: 100%;`
const CounterSection = styled.div`text-align: center; overflow: auto; position: absolute; top: 0px; right: 0px; width: 100%;`
const AnswersSection = styled.div`height: 40vh; margin-top: 10px; position: absolute; bottom: 0; left: 0; width: 100%;
	@media (max-width: 767px) {
		height: 37vh;
	}
`
const AnswerCounterSection = styled.div`text-align: center; overflow: auto; width: 100%;`

let interval
class PhaseAnswer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			counter: null,
			timeLimit: 0,
			started: false,
			isTimeLimited: true,
			videoVolume: 50,
			question: {
				question: '',
				answers: [],
			},
		}
		this.nextPhase = this.nextPhase.bind(this)
	}

	componentDidMount() {
		const that = this
		const question = this.props.game.quiz.questions[this.props.game.quiz.currentQuestion]
		this.setState({ question, isTimeLimited: this.props.game.quiz.timeLimit, timeLimit: question.timeLimit * 10 })

		if (this.props.game.quiz.timeLimit) {
			const playerKeys = this.props.game.players ? Object.keys(this.props.game.players) : []
			let counter = question.timeLimit
			let totalTime = question.timeLimit * 1000
			var start = new Date().getTime()
			interval = setInterval(() => {
				let answersCollected = 0
				
				for (let i = 0; i < playerKeys.length; i++) {
					if (that.props.game.players[playerKeys[i]].answers && that.props.game.players[playerKeys[i]].answers[question.id]) {
						answersCollected++
					}
				}

				var now = new Date().getTime()
				var elapsed = now - start
				counter = (totalTime - elapsed) / 100
				if (counter < 0) {
					counter = 0
				}
				// counter--
				that.setState({ counter, started: true })
				
				if (counter <= 0 || answersCollected === playerKeys.length) {
					that.nextPhase()
					clearInterval(interval)
				}
			}, 100)
		}
	}

	componentWillUnmount() {
		clearInterval(interval)
	}

	nextPhase() {
		this.props.gameFunc.update({ phase: 'result_question' })
	}

	render() {
		let currentQuestion = this.props.game.quiz.questions[this.props.game.quiz.currentQuestion]
		
		return (
			<div>
				{ currentQuestion &&
					<div>
						<QuestionSection>
							<QuestionContent question={currentQuestion} 
								imageBrushColor="red"
								videoPlaying={false} onVideoEnd={null} videoControlType="host" videoMaxHeight="240px"
								gameFunc={this.props.gameFunc} gameKey={this.props.game.key}
							/>
						</QuestionSection>
						
						<CounterSection>						
							{!this.state.isTimeLimited && <Button onClick={this.nextPhase}>Next</Button>}
							{this.state.isTimeLimited && <Timer startValue={this.state.timeLimit} value={this.state.counter} text={Math.ceil(this.state.counter / 10).toString()} />}
						</CounterSection>						

						<AnswersSection>
							<Grid container>
								<Grid item xs={12}>
									<AnswerCounterSection>
										<AnswerCounter game={this.props.game}/>
									</AnswerCounterSection>
								</Grid>
								<Grid item xs={12}>
									<AnswerOptionList question={currentQuestion} />
								</Grid>
							</Grid>
						</AnswersSection>
						<div style={{display: 'none'}}>
							<ReactPlayer url={`https://www.youtube.com/watch?v=09FF5_aM9rY&origin=${document.location.origin}`} playing={true} loop={true} volume={25} muted={false} controls={false}
								onError={(e) => { console.log('onError ', e) } }
							/>
						</div>
					</div>
				}
			</div>
		)
	}
}

export default PhaseAnswer
