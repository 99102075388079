import React from 'react'
import styled  from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { getPlayerConnectionURL } from '../../../utils/appUtil'
import { H1, BaseButton } from '../../../components'
import { ConnectedUsers } from '../shared/ConnectedUsers'
import ReactPlayer from 'react-player/youtube'

const GameTitle = styled.div`font-size:36px; font-weight: bold; color: navy; float: left; margin-left: 10px; margin-top: 10px;
	@media (max-width: 767px) {
		font-size: 24px;
	}
`
const CopyLink = styled.b`font-size: 12px; cursor: pointer; color: blue; text-decoration: underline;`
const PlayURL = styled(H1)`float: right; margin-right: 10px; margin-top: 10px;
	@media (max-width: 767px) {
		margin-top: 0px; margin-right: 5px; font-size: 18px;
	}
`
const StartButton = styled(BaseButton)`float: right; width: 240px; margin-right: 10px; `

function PhaseConnection(props) {
	const nextPhase = () => {
		props.gameFunc.update({ phase: 'starting' })
	}	
	let playURL = getPlayerConnectionURL(props.game.gameId)

	return (
		<Grid container>
			<Grid item xs={12} sm={6}>
				<GameTitle>{props.game.title}</GameTitle>
			</Grid>
			<Grid item xs={12} sm={6}>
				<div style={{float: 'right', marginRight: '10px', marginTop: '10px'}}>
					<PlayURL>
						<a href={playURL} target="_blank" rel="noopener noreferrer">{playURL}</a>
					</PlayURL>
					<CopyLink onClick={() => navigator.clipboard.writeText(playURL) } style={{fontSize: '12px'}}>(Copy)</CopyLink>
				</div>
			</Grid>

			<Grid item xs={12}>
				<ConnectedUsers game={props.game} animate={true} />
			</Grid>

			<Grid item xs={12}>
				<StartButton type="button" onClick={() => nextPhase()} value="DERSİ BAŞLAT" shadowColor="green" />
			</Grid>
	
			{
				props.game && props.game.players &&
					<Grid item xs={12} style={{display: 'none'}}>
						<ReactPlayer url={`https://www.youtube.com/watch?v=Q4Jq_ELdniI&origin=${document.location.origin}`} playing={true} loop={true} volume={50} muted={false} controls={false}
							onError={(e) => { console.log('onError ', e) } }
						/>
					</Grid>
			}
		</Grid>
	)
}

export default PhaseConnection
