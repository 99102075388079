import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClearIcon from '@material-ui/icons/Clear'
import FaceIcon from '@material-ui/icons/Face'
import AnswerOption from '../shared/AnswerOption'
import AnswerChart from '../shared/AnswerChart'
import Leaderboard from '../shared/Leaderboard'
import QuestionContent from '../shared/QuestionContent'
import MainDrawer from '../shared/MainDrawer'
import { Clearfix, Toggle } from '../../../components'

const QuestionNumber = styled.div`font-size: 18px; margin-top: 5px; font-weight: bold;`
const ScoreArea = styled.div`width: 100%; margin-top: 3px; margin-bottom: 3px;`
const ScoreTableBlock = styled.div`padding: 5px; border: solid 3px #3610c2; font-size: 14px; border-radius: 8px; font-family: Nunito; max-width: 400px; margin: auto; padding-bottom: 10px;`
const CorrectAnswerLabel = styled.div`color: green; font-size: 22px; font-weight: bold; margin-top: 5px; margin-bottom: 10px;`
const WrongAnswerLabel = styled.div`color: red; font-size: 22px; font-weight: bold; margin-top: 5px; margin-bottom: 10px;`
const NoAnswerLabel = styled.div`color: black; font-size: 22px; font-weight: bold; margin-top: 5px; margin-bottom: 10px;`

const PointBlock = styled.div`margin-top: 3px; 
	@media (max-width: 767px) {
		width: 100%;
		text-align: center;
	}
`
const PointsLabel = styled.span`color: black; font-size: 16px; `
const PointsValue = styled.span`color: navy; font-size: 20px; font-weight: bold;`

const AnswerChartArea = styled.div`margin-top: 3px; margin-bottom: 3px;
	@media (max-width: 767px) {
		width: 100%;
		height: 120px;
	}
`
const LeaderBoardArea = styled.div`margin-top: 3px; margin-bottom: 3px;`

const QuestionSection = styled.div`height: 54vh; text-align: center; overflow: auto; width: 100%;`
const AnswersSection = styled.div`height: 34vh; margin-top: 10px; position: absolute; bottom: 0; left: 0; width: 100%;
	@media (max-width: 767px) {
		height: 32vh;
	}
`
const tabs = [
	{ id: 'result', label: 'SONUÇ' },
	{ id: 'question', label: 'SORU' },
]

function PlayerQuestionResult(props) {
	const { playerData } = props
	console.log('playerData: ', playerData)
	const wrongAnswer = playerData.hasAnswered && (playerData.isCorrect === false)
	const correctAnswer = (playerData.isCorrect === true)
	const questionPoint = parseInt(props.currentQuestion.point)
	return (
		<ScoreArea>
			<ScoreTableBlock>
				{
					correctAnswer && <CorrectAnswerLabel><CheckCircleIcon style={{fontSize: '24px'}} /> Tebrikler, Doğru Cevap</CorrectAnswerLabel>
				}
				{
					wrongAnswer && <WrongAnswerLabel><ClearIcon style={{fontSize: '24px'}} /> Maalesef yanlış. Sonrakinde sana güveniyorum</WrongAnswerLabel>
				}
				{
					!playerData.hasAnswered && <NoAnswerLabel><FaceIcon style={{fontSize: '24px'}} /> Soruyu cevaplamadın. Bazı sorular zor olabilir.</NoAnswerLabel>
				}
				{
					playerData.hasAnswered && (questionPoint > 0) && (
						<div>
							<PointBlock>
								<PointsLabel>Puan: </PointsLabel>
								<PointsValue>{playerData.currentQuestionScore}</PointsValue>
							</PointBlock>

							{/* <PointBlock>
								<PointsLabel>Süre: </PointsLabel>
								<PointsValue>{playerData.answerTime}</PointsValue>
							</PointBlock> */}

							<PointBlock>
								<PointsLabel>Toplam Puan: </PointsLabel>
								<PointsValue>{playerData.totalScore}</PointsValue>
							</PointBlock>
							<Clearfix />
						</div>
					)
				}
				
				{
					(playerData.oldLeaderboardPosition !== playerData.newLeaderboardPosition) && (questionPoint > 0) && (
						<div>
							<PointBlock>
								<PointsLabel>Önceki Sıralaman:</PointsLabel>
								<PointsValue>{playerData.oldLeaderboardPosition}</PointsValue>
							</PointBlock>

							<PointBlock>
								<PointsLabel>Yeni Sıralaman: </PointsLabel>
								<PointsValue>{playerData.newLeaderboardPosition}</PointsValue>
							</PointBlock>
							<Clearfix />
						</div>
					)
				}

				{
					playerData.oldLeaderboardPosition === playerData.newLeaderboardPosition && (questionPoint > 0) && (
						<div>
							<PointBlock>
								<PointsLabel>Sıralamadaki Yerin: </PointsLabel>
								<PointsValue>{playerData.newLeaderboardPosition}</PointsValue>
							</PointBlock>
							<Clearfix />
						</div>
					)
				}
			</ScoreTableBlock>
		</ScoreArea>
	)
}

function PhaseResultQuestion(props) {
	const [tab, setTab] = useState((props.game.uiState && props.game.uiState.resultQuestionTab) ? props.game.uiState.resultQuestionTab : 'result')

	useEffect(() => {
		if (props.game.uiState) {
			setTab(props.game.uiState.resultQuestionTab)
		}
	}, [props.game.uiState])
	
	const getPlayerAnswerData = () => {
		const { game, playerKey } = props;
		const answerData = {
			totalScore: 0,
			currentQuestionScore: 0,
			newLeaderboardPosition: 1,
			oldLeaderboardPosition: 1,
			answerTime: 0,
			hasAnswered: false,
			isCorrect: false,
		}
		const currentQuestion = game.quiz.questions[game.quiz.currentQuestion];
		const currentPlayer = game.players[playerKey];
		
		for (let j = 0; j < game.quiz.questions.length; j++) {
			const question = game.quiz.questions[j];
			const answer = currentPlayer.answers ? currentPlayer.answers[question.id] : false;
			if (answer) {
				if (answer.questionId === currentQuestion.id) {
					answerData.hasAnswered = true
					answerData.currentQuestionScore = answer.score
					answerData.isCorrect = answer.isCorrect
					answerData.answerTime = answer.answerTime
				}
				answerData.totalScore += answer.score;
			}
		}

		const playerKeys = game.players ? Object.keys(game.players) : [];
		for (let i = 0; i < playerKeys.length; i++) {
			if (playerKeys[i] === currentPlayer.key) {
				continue
			}
			const opponent = game.players[playerKeys[i]]
			let totalScore = 0
			let currentQuestionScore = 0
			for (let j = 0; j < game.quiz.questions.length; j++) {
				const question = game.quiz.questions[j]
				const answer = opponent.answers ? opponent.answers[question.id] : false
				if (answer) {
					if (answer.questionId === currentQuestion.id) {
						currentQuestionScore = answer.score
					}
					totalScore += answer.score
				}
			}

			if (totalScore > answerData.totalScore) {
				answerData.newLeaderboardPosition += 1
			}
			if (totalScore - currentQuestionScore > answerData.totalScore - answerData.currentQuestionScore) {
				answerData.oldLeaderboardPosition += 1
			}
		}

		return answerData
	}

	
	const playerData = getPlayerAnswerData()

	if (props.game.quiz.remoteMode) {
		let currentQuestion
		if (props.game) {
			currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
		}
		const { answers } = currentQuestion
		return (
			<Grid container>
				<Grid item xs={12}>		
					<Grid container>
						<Grid item xs={2} sm={4}>
							<QuestionNumber>{props.game.quiz.currentQuestion + 1} / {props.game.quiz.questions.length}</QuestionNumber>
						</Grid>
						<Grid item xs={8} sm={4} style={{marginTop: '10px'}}>
							<Toggle options={tabs} value={tab} />
						</Grid>
						<Grid item xs={2} sm={4}>
						</Grid>
					</Grid>
				</Grid>
				
				{
					tab === 'result' &&
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12} style={{marginTop: '10px'}}>
									<PlayerQuestionResult playerData={playerData} currentQuestion={currentQuestion} />
								</Grid>
								
								<Grid item xs={12} sm={6}>
									<AnswerChartArea>
										<AnswerChart game={props.game} />
									</AnswerChartArea>					
								</Grid>

								<Grid item xs={12} sm={6}>
									<LeaderBoardArea>
										{ props.game.uiState &&
											<Leaderboard game={props.game} listCount="50" usedPage="play-result-question" tableHeight="40vh" />
										}
									</LeaderBoardArea>
								</Grid>
							</Grid>
						</Grid>
				}

				{
					tab === 'question' &&
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12}>
									<QuestionSection>
										<QuestionContent question={currentQuestion} videoPlaying={false} onVideoEnd={null} videoMaxHeight="240px" />
									</QuestionSection>

									<AnswersSection>
										<Grid container className="align-bottom">
											{
												answers.map((answer, index) => (
													<Grid key={index} item xs={currentQuestion.aType === 'multiple-image' ? 6 : 12} sm={6}>
														<AnswerOption answer={answer} index={index} aType={currentQuestion.aType} />
													</Grid>
												))
											}
										</Grid>
									</AnswersSection>
								</Grid>
							</Grid>
						</Grid>
				}
				{ props.game.uiState &&
					<div>					
						<MainDrawer content='question-list' gameInstance={props.game} float="left" showIcon={false} />
						<MainDrawer content='user-list' gameInstance={props.game} float="left" showIcon={false} />
						<MainDrawer content='report' gameInstance={props.game} float="left" showIcon={false} />
						<MainDrawer content='lesson-info' gameInstance={props.game} float="right" showIcon={false} />
					</div>
				}				
			</Grid>
		)
	}
	return (
		<div className="phase-container">
			<div className="quiz-top-section" />
			<div className="quiz-middle-section">
				<PlayerQuestionResult playerData={playerData} />
			</div>
			<div className="quiz-bottom-section">
				<Typography variant="subtitle1">Genel puan durumu için ekrana bakınız...</Typography>
			</div>
		</div>
	)
}

export default PhaseResultQuestion
