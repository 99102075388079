function generateGameId() {
	let id = ''
	const possible = 'ABCDEFGHJKLMNOPQRSTUVWXYZ0123456789'

	for (let i = 0; i < 6; i++) {
		id += possible.charAt(Math.floor(Math.random() * possible.length))
	}
	return id
}

function calculateDefaultTimeLimit(question) {
	if (question.aType === 'multiple' || question.aType === 'boolean') {
		return 10 + Math.floor((question.correctAnswers.join().length + question.wrongAnswers.join().length) / 30)
	}
	if (question.aType === 'free') {
		return 20
	}
	if (question.aType === 'player') {
		return 20
	}
	return 20
}

function parseDOM(s) {
	const parser = new DOMParser()
	const dom = parser.parseFromString(
		`<!doctype html><body>${s}`,
		'text/html',
	)
	const decodedString = dom.body.textContent
	return decodedString
}

function parseDOMArray(arr) {
	const result = []
	for (let i = 0; i < arr.length; i++) {
		result.push(parseDOM(arr[i]))
	}
	return result
}

function getPlayerConnectionURL(gameId) {
	const url = new URL(window.location.href)
	// console.log(url)
	const playerURL =  `${url.origin}/play/${gameId}`
	return playerURL
}

function getBrandData() {
	const url = new URL(window.location.href)
	let brandData = {
		logoFolder: '',
		appName: '',
		language: ''
	}
	switch(url.hostname) {
		case 'quiz.yesilokul.com':
			brandData = {
				logoFolder: 'quiz.yesilokul.com',
				brand: 'Yeşil Okul',
				appName: 'Yeşil Okul Ders Uygulaması',
				language: 'tr'
			}
			break
		case 'yarisma.sochool.com':
			brandData = {
				logoFolder: 'yarisma.sochool.com',
				brand: 'Sochool',
				appName: 'Sochool Quiz and Micro Education Application',
				language: 'tr'
			}
			break
		default:
			brandData = {
				logoFolder: 'quiz.yesilokul.com',
				brand: 'Yeşil Okul',
				appName: 'Yeşil Okul Ders Uygulaması',
				language: 'tr'
			}
			break
	}
	return brandData
}

function getURLData() {
	const url = new URL(window.location.href)
	return url
}


export {
	generateGameId,
	calculateDefaultTimeLimit,
	parseDOMArray,
	parseDOM,
	getPlayerConnectionURL,
	getBrandData,
	getURLData,
}
