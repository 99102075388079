import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { CardDiv, H1 } from '../../../components'
import Podium from '../shared/Podium'

const LessonDetailsComp = styled.div``
const PlayerListItem = styled.div``
const CorrectAnswer = styled.div`float: left; width: 24px; height: 24px; border-radius: 40px; background-color: green; color: white; text-align: center; font-size: 16px; margin: 1px; line-height: 24px;`
const WrongAnswer = styled.div`float: left; width: 24px; height: 24px; border-radius: 40px; background-color: red; color: white; text-align: center; font-size: 16px; margin: 1px; line-height: 24px;`
const NoAnswer = styled.div`float: left; width: 24px; height: 24px; border-radius: 40px; background-color:  #f2f2f2; color: white; text-align: center; font-size: 16px; margin: 1px; line-height: 24px;`
const NotAsked = styled.div`float: left; width: 24px; height: 24px; border-radius: 40px; background-color: gray; color: white; text-align: center; font-size: 16px; margin: 1px; line-height: 24px;`

function LessonDetails(props) {
	const [playerList, setPlayerList] = useState([])

	useEffect(() => {
		var tempPlayers = []
		if(props.gameInstance && props.gameInstance.players) {
			Object.keys(props.gameInstance.players).map((key) => tempPlayers.push(props.gameInstance.players[key]))
			setPlayerList(tempPlayers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))) // Sort by name
		}
	}, [])

    return (
		<LessonDetailsComp>
			<CardDiv>
				<Grid container key={props.gameInstance.key}>
					<Grid item xs={8}>
						<div style={{textAlign: 'left'}}>
							{
								props.gameInstance.status === 'CREATED' ?
									<a href={`/host-game/${props.gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
										<PlayCircleFilledIcon /> {props.gameInstance.title}
									</a>
								:
									props.gameInstance.status === 'IN_PROGRESS' ?
										<a href={`/host-game/${props.gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
											<PlayCircleFilledIcon /> {props.gameInstance.title}
										</a>
										:        
										<a href={`/host-game/${props.gameInstance.gameId}`} target="_blank" rel="noopener noreferrer">
											<AssessmentIcon /> {props.gameInstance.title}
										</a>
							}
						</div>
					</Grid>
					<Grid item xs={3}>
						<b style={{fontSize: '12px'}}>{props.gameInstance.gameId} ({props.gameInstance.players ?  Object.keys(props.gameInstance.players).length : 0})</b>
					</Grid>
					<Grid item xs={1}></Grid>
				</Grid>
			</CardDiv>
			
			<Grid container>
				<Grid item xs={12} sm={6}>
					<CardDiv>
						<H1>ÖĞRENCİ CEVAPLARI</H1>
						{
							props.gameInstance.players && playerList && playerList.map((player) =>
								<PlayerListItem key={player.key}>
									<Grid container>
										<Grid item xs={4}>
											{player.name}
										</Grid>
										<Grid item xs={8}>
											{
												props.gameInstance.quiz.questions.map((q, index) =>
													<span key={index}>
														{
															player.answers && player.answers[index + 1] ?
																player.answers[index + 1].isCorrect ?
																	<CorrectAnswer>{index + 1}</CorrectAnswer>
																:
																	<WrongAnswer>{index + 1}</WrongAnswer>
															: 
																index > props.gameInstance.quiz.currentQuestion || props.gameInstance.quiz.currentQuestion === 0 ?
																	<NoAnswer>{index + 1}</NoAnswer>
																:
																	<NotAsked>{index + 1}</NotAsked>															
														}
													</span>
												)
											}
										</Grid>
									</Grid>
								</PlayerListItem>
							)
						}						
					</CardDiv>
				</Grid>
				<Grid item xs={12} sm={6}>
					<CardDiv>
						<H1>PUAN TABLOSU</H1>
						<Podium game={props.gameInstance} />
					</CardDiv>
				</Grid>
			</Grid>
		</LessonDetailsComp>
    )
}

export default LessonDetails
