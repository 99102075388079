import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import AnswerOption from '../shared/AnswerOption'
import AnswerChart from '../shared/AnswerChart'
import MainDrawer from '../shared/MainDrawer'
// import AnswersBarChart from '../shared/AnswersBarChart'
import Leaderboard from '../shared/Leaderboard'
import QuestionContent from '../shared/QuestionContent'
import { BaseButton, Toggle } from '../../../components'

const QuestionNumber = styled.div`font-size: 18px; margin-top: 5px; font-weight: bold;`
const AnswerChartArea = styled.div`height: 140px; padding-top: 10px; margin: 5px; max-width: 300px; float: left; width: 95%;`
const BottomButtonsArea = styled(Grid)`position: absolute; bottom: 10px; height: 54px; width: 100%;
	@media (max-width: 767px) {
		bottom: 5px;
	}
`
const NextQuestionButton = styled(BaseButton)`width: 180px; height: 50px; margin-right: 10px;
	@media (max-width: 767px) {
		width: 120px;
		height: 40px;
		margin-right: 5px;
	}
`
const LeaderBoardArea = styled.div` margin: 5px; border-radius: 8px; margin-top: 0px;`
const QuestionSection = styled.div`height: 54vh; text-align: center; overflow: auto; width: 100%;`
const AnswersSection = styled.div`height: 34vh; margin-top: 10px; position: absolute; bottom: 0; left: 0; width: 100%;
	@media (max-width: 767px) {
		height: 32vh;
	}
`

const tabs = [
	{ id: 'result', label: 'SONUÇ' },
	{ id: 'question', label: 'SORU' },
]

function PhaseResultQuestion(props) {
	const [tab, setTab] = useState('result')
	const [leaderboardTab, setLeaderboardTab] = useState('currentQuestion')
	const [drawerQuestionList, setDrawerQuestionList] = useState('closed')
	const [drawerLessonInfo, setDrawerLessonInfo] = useState('closed')
	const [drawerUserList, setDrawerUserList] = useState('closed')
	const [drawerReport, setDrawerReport] = useState('closed')

	const [drawerActiveQuestionId, setDrawerActiveQuestionId] = useState(1)

	useEffect(() => {
		if (tab && leaderboardTab && drawerQuestionList && drawerLessonInfo && drawerUserList && drawerReport && drawerActiveQuestionId) {
			props.gameFunc.update({
				uiState: { 
					resultQuestionTab: tab,
					leaderboardTab: leaderboardTab,
					drawerQuestionList: drawerQuestionList,
					drawerLessonInfo: drawerLessonInfo,
					drawerUserList: drawerUserList,
					drawerReport: drawerReport,
					drawerActiveQuestionId: drawerActiveQuestionId
				} 
			})
		}
	}, [tab, leaderboardTab, drawerQuestionList, drawerLessonInfo, drawerUserList, drawerReport, drawerActiveQuestionId])
	
	const goNextQuestion = () => {
		const game = {}
		game.quiz = props.game.quiz
		game.quiz.currentQuestion = game.quiz.currentQuestion + 1
		game.phase = 'awaiting_question'
		props.gameFunc.update(game)
	}

	const finalizeQuiz = () => {
		props.gameFunc.update({ phase: 'final_result', status: 'COMPLETED' })
	}
	
	let answers = []
	let currentQuestion = ''
	
	if (props.game) {
		currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
		answers = currentQuestion.answers
	}

	const nextQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion + 1]
	const isLastQuestion = typeof nextQuestion === 'undefined'
	
	return (
		<Grid container>
			<Grid item xs={12}>		
				<Grid container>
					<Grid item xs={3} sm={4}>
						<QuestionNumber>{props.game.quiz.currentQuestion + 1} / {props.game.quiz.questions.length}</QuestionNumber>
					</Grid>
					<Grid item xs={6} sm={4} style={{marginTop: '10px'}}>
						<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
					</Grid>
					<Grid item xs={3} sm={4}>
						{ props.game.uiState &&
							<MainDrawer content='lesson-info' gameInstance={props.game} float="right" iconColor="green" showIcon={true} onStateChange={(newState) => setDrawerLessonInfo(newState)} mode="active" />
						}
						{/* <LessonInfoDrawer gameInstance={props.game} float="right" /> */}
					</Grid>
				</Grid>
			</Grid>
			
			{
				tab === 'result' &&
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<AnswerChartArea>
									<AnswerChart game={props.game} />
									{/* <AnswersBarChart game={props.game} /> */}
								</AnswerChartArea>					
							</Grid>

							<Grid item xs={12} sm={6}>
								<LeaderBoardArea>
									{ props.game.uiState &&
										<Leaderboard game={props.game} listCount="50" usedPage="host-result-question" tableHeight="calc(100vh - 350px)" selectTab={(newValue) => {setLeaderboardTab(newValue)}} />
									}
								</LeaderBoardArea>
							</Grid>

							<BottomButtonsArea item xs={12}>
								<Grid container>
									<Grid item xs={6}>
										{ props.game.uiState &&
											<div style={{width : '100%'}}>
												<MainDrawer content='question-list' gameInstance={props.game} float="left" showIcon={true} onStateChange={(newState) => setDrawerQuestionList(newState)} mode="active" 
													onActiveQuestionChange={(q) => { if(q) { setDrawerActiveQuestionId(q.id)} } }  />
												<MainDrawer content='user-list' gameInstance={props.game} float="left" iconColor="red" showIcon={true} onStateChange={(newState) => setDrawerUserList(newState)} mode="active" />
												<MainDrawer content='report' gameInstance={props.game} float="left" iconColor="blue" selectTab={(newValue) => {setLeaderboardTab(newValue)}} showIcon={true} onStateChange={(newState) => setDrawerReport(newState)} mode="active" />
											</div>
										}
									</Grid>
									<Grid item xs={6}>
										<div style={{width: '100%', textAlign: 'right'}}>
										{isLastQuestion && <NextQuestionButton onClick={() => finalizeQuiz()} value="TAMAMLA" type="button" fontSize="24px" />}
										{!isLastQuestion && <NextQuestionButton onClick={() => goNextQuestion()} value="DEVAM" type="button" fontSize="24px" />}	
										</div>
									</Grid>
								</Grid>
							</BottomButtonsArea>
						</Grid>
					</Grid>
			}

			{
				tab === 'question' &&
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12}>
								<QuestionSection>
									<QuestionContent question={currentQuestion} videoPlaying={false} onVideoEnd={null} videoMaxHeight="240px" mode="active" />
								</QuestionSection>

								<AnswersSection>
									<Grid container className="align-bottom">
										{
											answers.map((answer, index) => (
												<Grid key={index} item xs={currentQuestion.aType === 'multiple-image' ? 6 : 12} sm={6}>
													<AnswerOption answer={answer} index={index} remoteMode={true} aType={currentQuestion.aType} />
												</Grid>
											))
										}
									</Grid>
								</AnswersSection>
							</Grid>
						</Grid>
					</Grid>
			}
		</Grid>
	)
}

export default PhaseResultQuestion
