import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ThemeColors } from '../../utils/defaultConstants'

const ToggleMain = styled.div`border: solid 1px ${ThemeColors().themeColor7}; padding: 2px; border-radius: 6px; margin-left: 2px; margin-right: 2px;
						width: ${(props) => props.width ? props.width : 'calc(100% - 20px)'};
						height: ${(props) => props.height ? props.height + 'px' : '42px'};
						float: ${(props) => props.float ? props.float : ''};
						-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);
						@media (max-width: 767px) { 
							padding: 2px;
							width: ${(props) => props.mWidth ? props.mWidth : ''};
						}
					`
const EmptyOption = styled.div`color: #eeeeee; text-align:center; float:left; cursor:pointer; color: black;
						height: ${(props) => props.height ? parseInt(props.height)-6 + 'px' : '36px'};
						padding: ${(props) => props.padding ? props.padding : '3px'};
					`
const SelectedOption = styled(EmptyOption)`border: solid 1px ${ThemeColors().themeColor7}; border-radius: 6px; color: white !important; font-weight: bold !important; background: ${ThemeColors().themeColor3};
						height: ${(props) => props.height ? parseInt(props.height)-6 + 'px' : '36px'};
						padding: ${(props) => props.padding ? props.padding : '3px'};
					`
const OptionText = styled.span`
						font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};
						@media (max-width: 767px) { 
							font-size: ${(props) => props.mFontSize ? props.mFontSize : '11px'};
						}

`

function Toggle(props) {
	const [toggleItems, setToogleItems] = useState([])
	const options = props.options
	let itemWidthPercent = `${Math.round(100/options.length) - 3}%`

	useEffect(() => {
		let selectedOption = props.value ? props.value : options[0].id
		let tempToggleItems = []
		options.map(option => {
			option.id === selectedOption ?
			tempToggleItems.push(
						<SelectedOption style={{width: itemWidthPercent }} id={option.id} key={option.id} status="selected" padding={props.padding} height={props.height}>
							<OptionText fontSize={props.fontSize}>{option.label}</OptionText>
						</SelectedOption>
					)
				:
				tempToggleItems.push(
						<EmptyOption style={{width: itemWidthPercent }} id={option.id} key={option.id} status="notSelected" onClick={() => { if(props.onChange) { props.onChange(option.id) } } } padding={props.padding} height={props.height}>
							<OptionText fontSize={props.fontSize}>{option.label}</OptionText>
						</EmptyOption>
					)

			return null
		})
		setToogleItems(tempToggleItems)
	}, [props.value, props.options])

	return (
		<ToggleMain width={props.width} mWidth={props.mWidth} height={props.height} float={props.float}>
			{toggleItems}
		</ToggleMain>
	)
}

export { Toggle }
