import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const SelectComp = styled(Select)`width: 100%; height: 32px; font-size: 18px; padding-left: 2px;`

function BaseSelect(props) {

	const csStyles = {
		dropdownIndicator: () => ({ color: props.dropdownIndicatorColor ? props.dropdownIndicatorColor : '' }), 
		singleValue: (provided) => ({ ...provided, color: props.singleValueColor ? props.singleValueColor : '' }),
		placeholder: (provided) => ({ ...provided, color: props.placeHolderColor ? props.placeHolderColor : '' }),
		menu: (provided) => ({ ...provided, border: props.menuBorder ? props.menuBorder : '' }),
		control: (provided) => ({ ...provided, border: props.controlBorder ? props.controlBorder : (  (props.showAsRequired && props.value.length === 0) ? 'solid 2px red' : ''),
												'-webkit-box-shadow': 'inset 0 0 6px rgba(30,30,30,0.25)',
										':hover': {border: props.controlHoverBorder ? props.controlHoverBorder : ''} }),
	}

	return (
		<SelectComp 
			onChange={(newValue) => { props.onChange(newValue)} }
			options={props.options}
			value={props.value}
			style={{
				border: ((props.showAsRequired && props.value.length === 0) ? 'solid 1px red' : 'solid 1px gainsboro'),
			}}
			styles={csStyles}
		/>
	)
}

export { BaseSelect }