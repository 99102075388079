import React from 'react'
import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'

const TimerArea = styled.div`width: 100%; clear: both; height: 52px;
	@media (max-width: 767px) {
		height: 52px;
	}
`
const SecondsText = styled.div`font-size: 32px; height: 42px; text-align: right; font-weight: bold; margin-right: 80px; color: red;`

function Timer(props) {	
	const { text, value, startValue, } = props

	let currentCount = value === null ? startValue : value
	const start = startValue < 1 ? 0 : startValue

	if (currentCount < 0) {
		currentCount = 0
	}
	const completed = currentCount / start

	return (
		<TimerArea>
			<SecondsText style={{color: (completed * 100) > 50 ? 'green' : ( (completed * 100) > 33 ? 'orange' : 'red') }}>
				{text}
			</SecondsText>
			<LinearProgress 
				variant="determinate"
				value={completed * 100}
				style={{
					width: '100%',
					bottom: '0px',
					backgroundColor: (completed * 100) > 50 ? 'green' : ( (completed * 100) > 33 ? 'orange' : 'red'),
				}}
			/>
		</TimerArea>
	)
}

export { Timer }
