import React, { useState, useEffect } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { calculateDefaultTimeLimit } from '../../../utils/appUtil'
import QuestionListItem from '../shared/QuestionListItem'
import { updateQuestionListInQuizInTemplate } from '../../../utils/firebaseGameCreationUtils'
import { DrawerContentArea } from '../../../components'

const QuestionCard = styled.div`margin-bottom: 30px; margin-left: 2px; width: calc(100% - 4px); `
const ButtonArea = styled.div`position: -webkit-sticky; position: sticky; top: 0px; zIndex: 10; background-color: white; text-align: center; height: 60px;`

function QuestionList(props) {
	const [questions, setQuestions] = useState([])

	const [gameKey, setGameKey] = useState('')
	const [timeLimit, setTimeLimit] = useState(true)

	useEffect(() => {
		if (props.game) {
			setGameKey(props.game.key)
			
			setTimeLimit(props.game.quiz.timeLimit)
			if (props.game.quiz.questions && props.game.quiz.questions.length > 0) {
				let tempQuestions = []
				props.game.quiz.questions.map(q => {
					if (q) {
						tempQuestions.push(q)
					}
					return null
				})
				setQuestions(tempQuestions)
				if (props.activeQuestion) {
					let activeQuestionID = props.activeQuestion ? (props.activeQuestion.id === 0 ? 0 : props.activeQuestion.id - 1) : 0
					props.setActive(props.game.quiz.questions[activeQuestionID])
				}
			}
		}
	}, [props.game])

	const reOrderQuestions = (newOrderedQuestions) => {
		let tempQuestions = []
		let newQIndex = 0
		for (let i = 0; i < newOrderedQuestions.length; i++ ) {
			let tempQuestion = newOrderedQuestions[i]
			if (tempQuestion) {
				newQIndex++
				tempQuestion.id = newQIndex
				tempQuestions.push(tempQuestion)
			}
		}			
		updateQuestionListInQuizInTemplate(gameKey, tempQuestions, () => {})
	}
	
	const deleteQuestion = (questionToDelete) => {
		let tempQuestions = []
		let newQIndex = 0
		for (let i = 0; i < questions.length; i++ ) {
			let tempQuestion = questions[i]
			if (tempQuestion) {
				if (questionToDelete.id !== tempQuestion.id) {
					newQIndex++
					tempQuestion.id = newQIndex
					tempQuestions.push(tempQuestion)
				}
			}
		}
		setQuestions(tempQuestions)
		updateQuestionListInQuizInTemplate(gameKey, tempQuestions, () => {})
	}

	const addNewQuestion = () => {
		const question = {
			question: '',
			questionImageURL: '',
			questionVideoURL: '',
			startSecond: 0,
			endSecond: 1000000,			
			correctAnswers: [''],
			wrongAnswers: ['', '', ''],
			timeLimit: 10,
			point: 1000,
			qType: 'image',
			aType: 'multiple',
		}
		
		if (questions.length === 0) {
			question.id = 1
		} else {
			question.id = Math.max(...questions.map(q => q && q.id)) + 1
		}

		if (timeLimit && !question.timeLimit) {
			question.timeLimit = calculateDefaultTimeLimit(question)
		}

		let tempQuestions = [...questions]
		tempQuestions.push(question)
		setQuestions(tempQuestions)
		props.setActive(question)
	}

	useEffect(() => {
		if (props.activeQuestion && props.activeQuestion.id === questions.length && props.activeQuestion.question === '' && props.activeQuestion.questionImageURL === '' && props.activeQuestion.questionVideoURL === '') { //Only for new added question
			let element = document.getElementById(`q_${props.activeQuestion.id}`)
			element.scrollIntoView()
		}
	}, [props.activeQuestion])

    return (
		<div>
			<DrawerContentArea style={{height: isMobile ? 'calc(100vh - 70px)': 'calc(100vh - 160px)', padding: '0px', margin: '0px'}} >
				<ButtonArea>
					<Fab color="primary" aria-label="add" size="small">
						<AddIcon onClick={() => addNewQuestion()} />
					</Fab>
				</ButtonArea>
				<ReactSortable list={questions} setList={(e) => {reOrderQuestions(e)}} delayOnTouchStart={true} delay={10}>
					{
						questions.map((question) => question &&
							<QuestionCard key={question.id}>
								<QuestionListItem question={question} id={`q_${question.id}`} activeQuestion={props.activeQuestion}
									deleteQuestion={(q) => deleteQuestion(q)}
									selectQuestion={(q) => { props.setActive(q) }}
								/>
							</QuestionCard>
						)
					}
				</ReactSortable>
			</DrawerContentArea>
		</div>
    )
}

export default QuestionList
