import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const QuestionComp = styled.div`text-align: left; cursor: pointer;
    border: ${(props) => props.selected ? 'solid 2px navy' : 'solid 1px gainsboro'}; 
    border-radius: 6px; 
    width: 99%;
    -webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);
`
const Content = styled.div`height: 110px; `
const Footer = styled.div`height: 24px; overflow: auto; border-top: solid 1px gray; padding-right: 10px;`

const QuestionText = styled.div`font-size: 12px; font-weight: bold; text-align: center; margin: 2px; height: 18px; overflow: hidden;`
const QuestionTime = styled.div`font-size: 10px; color: red; text-align: center; width: 20px; border: solid 1px blue; border-radius: 60px; margin-left: 5px;`
const Point = styled.div`font-size: 10px; color: green; text-align: center; width: 40px; border: solid 1px green; border-radius: 60px; margin-right: 5px;`
const QuestionImageArea = styled.div`height: 50px; width: 100%; text-align: center; justify-content: center; border: solid 1px gainsboro; overflow: hidden;`
const QuestionImage = styled.img`max-width: 100%; max-height: 100%; margin: auto`
const CorrectAnswerText = styled.div`margin-left: 3px; margin-right: 3px; color: green; font-weight: bold; font-size: 10px; height: 14px; overflow: hidden; border: solid 1px green; border-radius: 4px; margin-top: 2px; padding-left: 3px;`
const WrongAnswerText = styled.div`margin-left: 3px; margin-right: 3px; color: red; font-size: 10px; height: 14px; overflow: hidden; border: solid 1px red; border-radius: 4px; margin-top: 2px; padding-left: 3px;`
const Info = styled.div`float: left; margin-left: 10px;`
const Number = styled.span`width: 30px; border-radius: 60px; background-color: #2b4db3; color: white;`
const Buttons = styled.div`text-align: right; width: 100%; padding: 0px; `
const IconButton = styled.span`cursor: pointer; margin-left: 10px; margin-right: 10px;`

function QuestionListItem(props) {
    const [openDialog, setOpenDialog] = React.useState(false);


    const qType = props.question.qType
    const aType = props.question.aType
    // console.log('props.question: ', props.question)
    // console.log('props.activeQuestion: ', props.activeQuestion)
    return (
        <QuestionComp selected={(props.activeQuestion && props.question.id === props.activeQuestion.id) ? true : false } id={props.id}>
            <Content onClick={() => { props.selectQuestion(props.question) }}>
                <Grid container>
                    <Grid item xs={12}>
                        <QuestionText style={{height: ((qType === 'image' || qType === 'video' || qType === 'imageContent' || qType === 'videoContent') ? '18px' : '50px')  }}>
                            {props.question.question}
                        </QuestionText>
                    </Grid>
                    <Grid item xs={3}>
                        {
                            (qType === 'text' || qType === 'image' || qType === 'video') && props.showMeta !== false &&
                                <QuestionTime>
                                    {props.question.timeLimit ? props.question.timeLimit + '' : '-'}
                                </QuestionTime>
                        }
                    </Grid>

                    <Grid item xs={6}>
                    {
                        (qType === 'image'|| qType === 'imageContent') &&                    
                            <QuestionImageArea>
                                {
                                    props.question.questionImageURL && props.question.questionImageURL.length > 0 &&
                                        <QuestionImage src={props.question.questionImageURL} alt="Question Preview" />
                                }
                            </QuestionImageArea>
                    }
                    {
                        (qType === 'video' || qType === 'videoContent') &&
                            <QuestionImageArea>
                                {
                                    props.question.questionVideoURL && props.question.questionVideoURL.length > 0 &&
                                        props.question.questionVideoURL.includes("youtube.com/watch") ?
                                            <img src={ props.question.questionVideoURL.replace('https://www.youtube.com/watch?v=', 'https://i.ytimg.com/vi/') + '/hqdefault.jpg' } style={{width: 80}} alt="Video" />
                                        :
                                            <div>VIDEO</div>
                                }
                            </QuestionImageArea>
                    }
                    </Grid>

                    <Grid item xs={3}>
                        {
                            (qType === 'text' || qType === 'image' || qType === 'video') && props.showMeta !== false &&
                                <Point>
                                    {props.question.point ? props.question.point + '' : '0'}
                                </Point>
                        }
                    </Grid>
                    {
                        (qType === 'text' || qType === 'image' || qType === 'video') && props.showAnswers !== false &&
                            <Grid item xs={12}>
                                <Grid container>
                                    {
                                        props.question.correctAnswers.map((answer, index) =>
                                            <Grid item xs={6} key={index}>
                                                <CorrectAnswerText>
                                                {
                                                    aType === "multiple-image" ?
                                                        props.question.correctAnswersImages && props.question.correctAnswersImages[index] && props.question.correctAnswersImages[index].url ?
                                                            <img src={props.question.correctAnswersImages[index].url} style={{ height: '15px' }} alt="Correct Answer" />
                                                        :
                                                            <span>-</span>
                                                    : 
                                                        <span>{answer}</span>
                                                }
                                                </CorrectAnswerText>
                                            </Grid>        
                                        )
                                    }
                                    {
                                        props.question.wrongAnswers.map((answer, index) =>
                                            <Grid item xs={6} key={index}>
                                                <WrongAnswerText>
                                                {
                                                    aType === "multiple-image" ?
                                                        props.question.wrongAnswersImages && props.question.wrongAnswersImages[index] && props.question.wrongAnswersImages[index].url ?
                                                            <img src={props.question.wrongAnswersImages[index].url} style={{ height: '15px' }} alt="Wrong answer" />
                                                        :
                                                            <span>-</span>
                                                    : 
                                                        <span>{answer}</span>
                                                }
                                                </WrongAnswerText>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Content>
            <Footer>
                <Info>
                    <Number>
                        &nbsp;{parseInt(props.question.id)}&nbsp;
                    </Number>
                </Info>
                <Buttons>
                    {
                        props.showDelete !== false &&
                            <IconButton onClick={() => setOpenDialog(true) } >
                                <DeleteIcon style={{fontSize: '12px', marginTop: '0px'}}/>
                            </IconButton>
                    }
                </Buttons>
            </Footer>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"Silmek istediğinizden emin misiniz?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Slayt içeriğini silmek istediğinizden emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Iptal
                    </Button>
                    <Button onClick={() => { console.log('delete q: ', props.question); props.deleteQuestion(props.question)}} color="primary" autoFocus>
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        </QuestionComp>
    )
}

export default QuestionListItem
