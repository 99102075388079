import React, { useState } from 'react'
import { Helmet } from "react-helmet"
import './App.scss'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Create from './pages/Create'
import Host from './pages/Host'
import Play from './pages/Play'
import Logout from './pages/Logout'
import { CustomizedSnackbars } from './components'
import { getBrandData } from './utils/appUtil'
import { AuthProvider } from './data/context/AuthProvider'

function App(props) {
	const [snack, setSnack] = useState('')
	
	const hideSnackbar = () => {
		const tempSnack = { ...snack}
		tempSnack.open = false
		setSnack(tempSnack)
	}

	const showSnackbar = (tempSnack) => {		
		tempSnack.open = true
		setSnack(tempSnack)
	}
	
	return (
		<AuthProvider>
			<Router>
				<div className="App">
					<Helmet>
						<link rel="shortcut icon" href={`/images/${getBrandData().logoFolder}/favicon.ico`} />
						<title>{getBrandData().appName}</title>
						<link rel="manifest" href={`/images/${getBrandData().logoFolder}/manifest.webmanifest`} />
					</Helmet>
					<div id="content" style={{clear: 'both'}}>
						
						<Route path="/play/:pincode" render={(props) => <Play showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/play" render={(props) => <Play showSnackbar={showSnackbar} {...props} />} />
						
						<Route path="/host-game/:pincode" render={(props) => <Host showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/host-game" render={(props) => <Host showSnackbar={showSnackbar} {...props} />} />
						
						<Route path="/create-new-game/:pincode" render={(props) => <Create showSnackbar={showSnackbar} {...props} />} />
						<Route exact path="/create-new-game" render={(props) => <Create showSnackbar={showSnackbar} {...props} />} />
						
						<Route exact path="/logout" render={(props) => <Logout showSnackbar={showSnackbar} {...props} />} />

						<Route exact path="/" render={(props) => <Play showSnackbar={showSnackbar} {...props} />} />
					</div>
					{
						snack && 
							<CustomizedSnackbars snack={snack} hideSnackbar={hideSnackbar} />
					}
				</div>
			</Router>
		</AuthProvider>
	)
}

export default App
