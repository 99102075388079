import React from 'react'
import styled from 'styled-components'
import QuestionContent from '../shared/QuestionContent'
import { H1 } from '../../../components'

const QuestionSection = styled.div`text-align: center; overflow: auto; width: 100%;`

function PhaseShowContent(props) {
	let currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
	if (props.game.quiz.remoteMode) {
		return (
			<div>
				{ currentQuestion &&
					<QuestionSection>
						<QuestionContent question={currentQuestion} videoPlaying={false} onVideoEnd={null} videoUseMaxScreen={true} />
					</QuestionSection>
				}
			</div>
		)
	} else {
		return (
			<H1>Soru için ana ekrana bakın!</H1>
		)
	}
}

export default PhaseShowContent
