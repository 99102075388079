import React from 'react'

function PhaseEnd(props) {
    return (
        <div className="phase-container">
            Oyun Sonlandı
        </div>
    )
}

export default PhaseEnd
