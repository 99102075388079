import React, { useState, useEffect, useCallback } from 'react'
import {unstable_batchedUpdates} from 'react-dom'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import {useDropzone} from 'react-dropzone'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ClearIcon from '@material-ui/icons/Clear'
import { updateQuestionDataInTemplate } from '../../../utils/firebaseGameCreationUtils'
import { FormLine, FormElementLabel, BaseInput, EmptySpace } from '../../../components'
import { storage } from '../../../utils/firebaseSettings'
import QuestionVideo from '../shared/QuestionVideo'

const ImageUploadContainer = styled.div`background: #dadada; display: flex; justify-content: center; align-items: center; text-align: center; border-radius: 8px; font-size: 12px;
	height: ${(props) => props.height ? props.height : 'calc(100vh - 600px)'};
	padding: ${(props) => props.padding ? props.padding : '50px'};
	margin: ${(props) => props.margin ? props.margin : '10px'};
`
function BasicImageDropzone(props) {
	const onDrop = useCallback(acceptedFiles => {
		if(acceptedFiles[0] === '') {
			console.error(`not an image, the image file is a ${typeof(acceptedFiles[0])}`)
		}
		let timestamp = Date.now()
		let fileName =`${props.type}-${timestamp}-${acceptedFiles[0].name}`
		const uploadTask = storage.ref(`/MICRO-LESSON-IMAGES/${props.gameTemplateKey}/${fileName}`).put(acceptedFiles[0])
		uploadTask.on("state_changed", console.log, console.error, () => {
			storage
				.ref(`/MICRO-LESSON-IMAGES/${props.gameTemplateKey}`)
				.child(`${fileName}`)
				.getDownloadURL()
				.then((url_uploaded) => {
					props.onUploadComplete({
						url: url_uploaded,
						fileName: fileName
					})
				})
		})
	}, [])

	const {getRootProps, getInputProps} = useDropzone({onDrop})
  
	return (
		<ImageUploadContainer {...getRootProps({className: 'dropzone'})} height={props.height ? props.height: null} padding={props.padding ? props.padding: null} margin={props.margin ? props.margin: null} >
			<input {...getInputProps()} />
			<p>Resmi sürükleyip bu alana bırakın veya seçmek için tıklayın</p>
		</ImageUploadContainer>
	)
}
  
const QuestionFormArea = styled.div`margin-top: 20px; margin-bottom: 50px; border: solid 1px gainsboro; border-radius: 6px; padding: 10px; background-color: #f9f9f9;
	-webkit-box-shadow: 2px 2px 5px 0px #cccccc; -moz-box-shadow: 2px 2px 5px 0px #cccccc; box-shadow: 2px 2px 5px 0px #cccccc;
`
const AnswerImageGrid = styled(Grid)`border-radius: 8px; margin-top: 3px;`

let changeFromInside = false
const emptyFileObj = {url: '', fileName: ''}

function QuestionForm(props) {	
	const [id, setId] = useState(props.activeQuestion.id)
	const [question, setQuestion] = useState('')
	const [questionImageURL, setQuestionImageURL] = useState('')
	const [questionImageFileName, setQuestionImageFileName] = useState('')
	const [questionVideoURL, setQuestionVideoURL] = useState('')
	const [startSecond, setStartSecond] = useState(0)
	const [endSecond, setEndSecond] = useState(1000000)
	
	const [correctAnswer, setCorrectAnswer] = useState('')
	const [wrongAnswerOne, setWrongAnswerOne] = useState('')
	const [wrongAnswerTwo, setWrongAnswerTwo] = useState('')
	const [wrongAnswerThree, setWrongAnswerThree] = useState('')

	const [correctAnswerImage, setCorrectAnswerImage] = useState(emptyFileObj)
	const [wrongAnswer1Image, setWrongAnswer1Image] = useState(emptyFileObj)
	const [wrongAnswer2Image, setWrongAnswer2Image] = useState(emptyFileObj)
	const [wrongAnswer3Image, setWrongAnswer3Image] = useState(emptyFileObj)

	const [timeLimit, setTimeLimit] = useState(10)
	const [point, setPoint] = useState(1000)
	const [qType, setQType] = useState('image')
	const [aType, setAType] = useState('multiple')
	
	const [firstLoad, setFirstLoad] = useState(false)	
	
	const handleTrueFalse = (event) => {
		const answer = event.target.value		
		setCorrectAnswer(answer)
		setWrongAnswerOne(answer === 'True' ? 'False' : 'True')
		setWrongAnswerTwo('')
		setWrongAnswerThree('')
	}	

	useEffect(() => {
		changeFromInside = false
		if (props.activeQuestion) {
			unstable_batchedUpdates(() => {
				setId(props.activeQuestion.id)
				setQuestion(props.activeQuestion.question)
				setQuestionImageURL(props.activeQuestion.questionImageURL)
				setQuestionImageFileName(props.activeQuestion.questionImageFileName)
				setQuestionVideoURL(props.activeQuestion.questionVideoURL)
				setStartSecond(props.activeQuestion.startSecond)
				setEndSecond(props.activeQuestion.endSecond)
				
				setCorrectAnswer(props.activeQuestion.correctAnswers[0])
				setWrongAnswerOne(props.activeQuestion.wrongAnswers[0])
				setWrongAnswerTwo(props.activeQuestion.wrongAnswers[1])
				setWrongAnswerThree(props.activeQuestion.wrongAnswers[2])

				setCorrectAnswerImage((props.activeQuestion.correctAnswersImages && props.activeQuestion.correctAnswersImages.length > 0) ? props.activeQuestion.correctAnswersImages[0] : emptyFileObj)
				setWrongAnswer1Image((props.activeQuestion.wrongAnswersImages && props.activeQuestion.wrongAnswersImages.length > 0) ? props.activeQuestion.wrongAnswersImages[0] : emptyFileObj)
				setWrongAnswer2Image((props.activeQuestion.wrongAnswersImages && props.activeQuestion.wrongAnswersImages.length > 1) ? props.activeQuestion.wrongAnswersImages[1] : emptyFileObj)
				setWrongAnswer3Image((props.activeQuestion.wrongAnswersImages && props.activeQuestion.wrongAnswersImages.length > 2) ? props.activeQuestion.wrongAnswersImages[2] : emptyFileObj)

				setTimeLimit(props.activeQuestion.timeLimit)
				setPoint(props.activeQuestion.point)
				setQType(props.activeQuestion.qType)
				setAType(props.activeQuestion.aType)
			})
			setFirstLoad(true)
		}		
	}, [props.activeQuestion])

	useEffect(() => {
		if (firstLoad && changeFromInside) {
			let q = {
				id,
				question,
				questionImageURL: questionImageURL ? questionImageURL : '',
				questionImageFileName: questionImageFileName ? questionImageFileName : '',
				questionVideoURL: questionVideoURL ? questionVideoURL : '',
				startSecond: startSecond ? startSecond : 0,
				endSecond: endSecond ? endSecond : 1000000,
				correctAnswers: [correctAnswer],
				correctAnswersImages: [correctAnswerImage],
				wrongAnswers: [wrongAnswerOne, wrongAnswerTwo, wrongAnswerThree],
				wrongAnswersImages: [wrongAnswer1Image, wrongAnswer2Image, wrongAnswer3Image],
				timeLimit: timeLimit ?  timeLimit : 10,
				point: point ? point : 1000,
				qType,
				aType,
			}
			
			let questionID = q.id === 0 ? 0 : q.id-1
			if (q !== props.questions[questionID] && q.title !== '') {
				updateQuestionDataInTemplate(props.gameKey, questionID, q, (error) => {
					if (error) {
						console.log('question update error: ', error)
					}
				})
			}
		}
	}, [question, questionImageURL, questionImageFileName, questionVideoURL, startSecond, endSecond, 
		correctAnswer, correctAnswerImage, wrongAnswerOne, wrongAnswerTwo, wrongAnswerThree, wrongAnswer1Image, wrongAnswer2Image, wrongAnswer3Image, 
		timeLimit, qType, aType, timeLimit, point])

	const deleteFile = (fileName) => {
		// deleteImageFromTemplate(props.gameKey, image)
		const fileRef = storage.ref(`/MICRO-LESSON-IMAGES/${props.gameKey}/${fileName}`)
		fileRef.delete().then(() => {

		}).catch((error) => {
			console.log('File could not be deleted.')
		})
	}

	return (
		<QuestionFormArea>
			<div style={{width: '100%'}}>
				<FormControl style={{width : '50%' }}>
					<InputLabel htmlFor="qtype-required">Soru Türü</InputLabel>
					<Select value={qType || ''} onChange={(e) =>  { changeFromInside = true; setQType(e.target.value)} } name="qType" inputProps={{ id: 'qtype-required', }}>
						<MenuItem value="text">Metin Soru</MenuItem>
						<MenuItem value="image">Resimli Soru</MenuItem>
						<MenuItem value="video">Videolu Soru</MenuItem>
						<MenuItem value="textContent">Metin İçerik</MenuItem>
						<MenuItem value="imageContent">Resimli İçerik</MenuItem>
						<MenuItem value="videoContent">Videolu İçerik</MenuItem>
					</Select>
				</FormControl>
				{
					(qType === 'text' || qType === 'image' || qType === 'video') &&
						<FormControl style={{width : '50%' }}>
							<InputLabel htmlFor="aType-required">Cevap Türü</InputLabel>
							<Select value={aType || ''}  onChange={(e) => { changeFromInside = true; setAType(e.target.value) } } inputProps={{ id: 'aType-required', }} >
								<MenuItem value="boolean">METİN - Doğru/Yanlış</MenuItem>
								<MenuItem value="multiple">METİN - Çoktan Seçmeli</MenuItem>
								<MenuItem value="multiple-image">RESİM - Çoktan Seçmeli</MenuItem>
								{/* <MenuItem value="player">Player</MenuItem>
								<MenuItem value="free">Free text</MenuItem> */}
							</Select>
						</FormControl>
				}
			</div>

			<FormLine>
				<FormElementLabel>Metin</FormElementLabel>
				<BaseInput value={question} maxlength="80" onChange={(newValue) => { changeFromInside = true; setQuestion(newValue); } } />
			</FormLine>

			{ (qType === 'image' || qType === 'imageContent') && 
				<FormLine>
					{
						questionImageURL && questionImageURL.length > 0 ?
							<div>
								<img src={questionImageURL} style={{maxHeight: '400px', maxWidth: '100%', marginBottom: '10px', borderRadius: '8px'}} alt="Question Preview" />
								<ClearIcon color="action" onClick={() => { changeFromInside = true; deleteFile(questionImageFileName); setQuestionImageURL(''); setQuestionImageFileName('');  }} title="Kaldır" style={{float: 'right'}} />							
							</div>
						:
							<div>
								<BasicImageDropzone onUploadComplete={(e) => { changeFromInside = true; setQuestionImageURL(e.url); setQuestionImageFileName(e.fileName); } } gameTemplateKey={props.gameKey} type="question-image" />
							</div>
					}
				</FormLine>
			}

			{ (qType === 'video' || qType === 'videoContent') && 
				<Grid container style={{marginTop: '10px'}}>					
					<Grid item xs={1}></Grid>
					<Grid item xs={10}>
						<FormElementLabel>Video URL</FormElementLabel>
						<BaseInput value={questionVideoURL} onChange={(newValue) => { changeFromInside = true; setQuestionVideoURL(newValue) } } />
					</Grid>
					<Grid item xs={1}></Grid>

					<Grid item xs={1}></Grid>
					<Grid item xs={10} sm={5}>
						<FormElementLabel>Başlangıç Zamanı (saniye)</FormElementLabel>
						<BaseInput value={startSecond} onChange={(newValue) => { changeFromInside = true; setStartSecond(newValue) } } type="number" />
					</Grid>
					<Grid item xs={10} sm={5}>
						<FormElementLabel>Bitiş Zamanı (saniye)</FormElementLabel>
						<BaseInput value={endSecond} onChange={(newValue) => { changeFromInside = true; setEndSecond(newValue); } } />
					</Grid>
					<Grid item xs={1}></Grid>

					<Grid item xs={12} style={{marginTop: '10px'}}>
						{
							questionVideoURL && questionVideoURL.length > 0 &&
								<QuestionVideo playing={false} question={props.activeQuestion} useMaxScreen={false} />
						}
					</Grid>
				</Grid>
			}
			<EmptySpace>&nbsp;</EmptySpace>
			{
				aType === 'multiple' && (qType === 'text' || qType === 'image' || qType === 'video') && (
					<Grid container>
						<Grid item xs={12} sm={1}></Grid>
						<Grid item xs={12} sm={4}>
							<FormElementLabel color="green" style={{fontSize: '12px'}}>Doğru Cevap</FormElementLabel>
							<BaseInput value={correctAnswer} onChange={(newValue) => { changeFromInside = true; setCorrectAnswer(newValue); } } color="green" />
						</Grid>
						<Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={4}>
							<FormElementLabel color="red" style={{fontSize: '12px'}}>Yanlış Cevap 1</FormElementLabel>
							<BaseInput value={wrongAnswerOne} onChange={(newValue) => { changeFromInside = true; setWrongAnswerOne(newValue) } } color="red" />
						</Grid>
						<Grid item xs={12} sm={1}></Grid>

						<Grid item xs={12} sm={1}></Grid>
						<Grid item xs={12} sm={4}>
							<FormElementLabel color="red" style={{fontSize: '12px'}}>Yanlış Cevap 2</FormElementLabel>
							<BaseInput value={wrongAnswerTwo} onChange={(newValue) => { changeFromInside = true; setWrongAnswerTwo(newValue) } } color="red" />
						</Grid>
						<Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={4}>
							<FormElementLabel color="red" style={{fontSize: '12px'}}>Yanlış Cevap 3</FormElementLabel>
							<BaseInput value={wrongAnswerThree} onChange={(newValue) =>  { changeFromInside = true; setWrongAnswerThree(newValue) } } color="red" />
						</Grid>
						<Grid item xs={12} sm={1}></Grid>
					</Grid>
				)
			}
			{
				aType === 'multiple-image' && (qType === 'text' || qType === 'image' || qType === 'video') && (
					<Grid container>
						<Grid item xs={12} sm={1}></Grid>
						<AnswerImageGrid item xs={12} sm={4} style={{border: 'solid 2px green'}}>
							{
								correctAnswerImage && correctAnswerImage.url && correctAnswerImage.url.length > 0 ?
									<div>
										<img src={correctAnswerImage.url} style={{ height: '50px' }} alt="Correct answer" />
										<ClearIcon color="action" onClick={() => { changeFromInside = true; deleteFile(correctAnswerImage.fileName); setCorrectAnswerImage(emptyFileObj);  }} title="Kaldır" style={{float: 'right'}} />
									</div>
								:
									<BasicImageDropzone onUploadComplete={(e) => { changeFromInside = true; setCorrectAnswerImage(e); } } gameTemplateKey={props.gameKey} type="correct-answer-image" height="50px" padding="10px" margin="3px" />
							}
						</AnswerImageGrid>
						<Grid item xs={12} sm={2}></Grid>
						<AnswerImageGrid item xs={12} sm={4} style={{border: 'solid 2px red'}}>
							{
								wrongAnswer1Image && wrongAnswer1Image.url && wrongAnswer1Image.url.length > 0 ?
									<div>
										<img src={wrongAnswer1Image.url} style={{ height: '50px' }} alt="Wrong answer 1" />
										<ClearIcon color="action" onClick={() => { changeFromInside = true; deleteFile(wrongAnswer1Image.fileName); setWrongAnswer1Image(emptyFileObj);  }} title="Kaldır" style={{float: 'right'}} />
									</div>
								:
									<BasicImageDropzone onUploadComplete={(e) => { changeFromInside = true; setWrongAnswer1Image(e); } } gameTemplateKey={props.gameKey} type="wrong-answer-image" height="50px" padding="10px" margin="3px" />
							}
						</AnswerImageGrid>
						<Grid item xs={12} sm={1}></Grid>

						<Grid item xs={12} sm={1}></Grid>
						<AnswerImageGrid item xs={12} sm={4} style={{border: 'solid 2px red'}}>
							{
								wrongAnswer2Image && wrongAnswer2Image.url && wrongAnswer2Image.url.length > 0 ?
									<div>
										<img src={wrongAnswer2Image.url} style={{ height: '50px' }} alt="Wrong answer 2" />
										<ClearIcon color="action" onClick={() => { changeFromInside = true; deleteFile(wrongAnswer2Image.fileName); setWrongAnswer2Image(emptyFileObj);  }} title="Kaldır" style={{float: 'right'}} />
									</div>
								:
									<BasicImageDropzone onUploadComplete={(e) => { changeFromInside = true; setWrongAnswer2Image(e); } } gameTemplateKey={props.gameKey} type="wrong-answer-image" height="50px" padding="10px" margin="3px" />
							}
						</AnswerImageGrid>
						<Grid item xs={12} sm={2}></Grid>
						<AnswerImageGrid item xs={12} sm={4} style={{border: 'solid 2px red'}}>
							{
								wrongAnswer3Image && wrongAnswer3Image.url && wrongAnswer3Image.url.length > 0 ?
									<div>
										<img src={wrongAnswer3Image.url} style={{ height: '50px' }} alt="Wrong answer 3" />
										<ClearIcon color="action" onClick={() => { changeFromInside = true; deleteFile(wrongAnswer3Image.fileName); setWrongAnswer3Image(emptyFileObj);  }} title="Kaldır" style={{float: 'right'}} />
									</div>
								:
									<BasicImageDropzone onUploadComplete={(e) => { changeFromInside = true; setWrongAnswer3Image(e); } } gameTemplateKey={props.gameKey} type="wrong-answer-image" height="50px" padding="10px" margin="3px" />
							}
						</AnswerImageGrid>
						<Grid item xs={12} sm={1}></Grid>
					</Grid>
				)
			}

			{
				aType === 'boolean' && (qType === 'text' || qType === 'image' || qType === 'video') && (
					<div>
						<FormLine>
							<FormElementLabel>Doğru Cevap</FormElementLabel>										
							<RadioGroup aria-label="answer" name="correctAnswer" value={correctAnswer} onChange={(e) => { changeFromInside = true; handleTrueFalse(e) } } >
								<FormControlLabel value="True" control={<Radio />} label="True" />
								<FormControlLabel value="False" control={<Radio />} label="False" />
							</RadioGroup>
						</FormLine>
					</div>
				)
			}
			
			{
				(qType === 'text' || qType === 'image' || qType === 'video') && (
					<Grid container>
						<Grid item xs={12} sm={6}>
							<FormLine>
								<FormElementLabel>Süre Limiti (saniye)</FormElementLabel>
								<BaseInput value={timeLimit} onChange={(newValue) => { changeFromInside = true; setTimeLimit(newValue); } } type="number" />
							</FormLine>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormLine>
								<FormElementLabel>Puan</FormElementLabel>
								<BaseInput value={point} onChange={(newValue) => { changeFromInside = true; setPoint(newValue) } } type="number" step="100" min="0" max="10000" />
							</FormLine>
						</Grid>
					</Grid>
				)
			}
			{/* <FormLine>
				<BaseButton onClick={() => sendQuestion()} variant="contained" value="Soruyu Ekle" shadowColor="navy" width="250px" style={{float: 'right'}} />
			</FormLine> */}
		</QuestionFormArea>
	)
}

export default QuestionForm
