import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../data/context/AuthProvider'
import { firebaseAuth } from '../utils'

function Logout(props) {
	let history = useHistory()
	const [, setAuthContextData] = useContext(AuthContext)

	useEffect(() => {
		firebaseAuth.signOut().then(() => {
			console.log('Logged out')
			localStorage.removeItem('email')
			setAuthContextData({
				user: null,
				email: '',
				name: '',
				roles: [],
				lessons: [],
			})
			history.push(`/`)
		})
	}, [props])

	return (
		<div>LOGGING OUT</div>
	)
}

export default Logout
