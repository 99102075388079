import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/firestore'

const firebaseConfig = {
	apiKey: "AIzaSyC2wTzGGl2KicRVD8D-9t32ByJPZOPIGqQ",
    authDomain: "yesil-okul-web-app.firebaseapp.com",
    databaseURL: "https://yesil-okul-web-app-default-rtdb.firebaseio.com",
    projectId: "yesil-okul-web-app",
    storageBucket: "yesil-okul-web-app.appspot.com",
    messagingSenderId: "1095413858826",
    appId: "1:1095413858826:web:29d13d43c50ffd116b15f3",
    measurementId: "G-L8HGYT4ZG6"
}


let fire
if (!firebase.apps.length) {
	fire = firebase.initializeApp(firebaseConfig)
} else {
	
}

const firebaseAuth = firebase.auth()
const firestore = firebase.firestore()
const storage = firebase.storage()

export {
    fire,
    firebaseAuth,
    firestore,
    storage
}
