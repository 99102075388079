import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getGameInstanceData, getGameInstanceRef, addPlayerAndReturnRef } from '../utils/firebaseGameHostAndPlayUtils'
import Quiz from '../PageBlocks/quiz/play/Quiz'
import { Layout2, H1, FormLine, BaseInput, BaseButton } from '../components'

const PlayPage = styled.div`margin: 10px; max-width: 400px; width: 90%; margin-left: auto; margin-right: auto;`
const GameJoinBlock = styled.div`margin-top: 100px;`
const SubmitButton = styled(BaseButton)`float: right; width: 200px;`

function Play(props) {
	const [game, setGame] = useState({})
	const [gameId, setGameId] = useState((props && props.match && props.match.params && props.match.params.pincode) ? props.match.params.pincode : '')
	const [playerKey, setPlayerKey] = useState('')

	useEffect(() => {
		if (gameId) {
			joinGame(gameId)
		}
	}, [])

	const joinGame = (gameId) => {
		getGameInstanceData(gameId, (gameData) => {
			if (gameData) {
				if (gameData.phase === 'setup') {
					const snack = { variant: 'error', message: 'Henüz başlatılmadı' }
					props.showSnackbar(snack)
				} else {
					const storedPlayerKey = localStorage.getItem('RecentPlayerKey')
					
					if (storedPlayerKey && gameData.players && gameData.players[storedPlayerKey]) {
						setPlayerKey(storedPlayerKey)
					}
					initGameListener(gameData)
				}
			} else {
				const snack = { variant: 'info', message: 'Bu PIN için oyun bulunamadı' }
				props.showSnackbar(snack)
			}
		})
	}

	const initGameListener = (_game) => {
		let gameRef = getGameInstanceRef(_game.key)

		gameRef.on('value', (snapshot) => {
			const game = snapshot.val()
			if (game) {
				setGame(game)
			} else {
				setGame('')
			}
		})
	}

	const createPlayer = (player) => {
		let playerRef = addPlayerAndReturnRef(game.key)

		const newPlayer = Object.assign({ key: playerRef.key }, player)
		
		playerRef.set(newPlayer, (error) => {
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
			} else {
				setPlayerKey(newPlayer.key)
				localStorage.setItem('RecentPlayerKey', newPlayer.key)
			}
		})
	}

	return (
		<Layout2 withAuth={false} game={game}>
			{
				(!game || !game.phase) &&
					<PlayPage>
						<GameJoinBlock>
							<H1>PIN KODU</H1>
							<FormLine>							
								<BaseInput name="gameID" value={gameId} onChange={(newValue) => setGameId(newValue)} color="red" fontSize="48px" textAlign="center" height="60px" />
							</FormLine>
							<FormLine>
								<SubmitButton type="button" onClick={() => joinGame(gameId)} value="OYUNA KATIL" />
							</FormLine>
						</GameJoinBlock>
					</PlayPage>
			}
			{
				(game && game.phase) &&
					<div className="page-container play-page">
						{game.gameType === 'quiz' && <Quiz game={game} createPlayer={createPlayer} playerKey={playerKey} showSnackbar={props.showSnackbar} />}
					</div>
			}
		</Layout2>
	)
}

export default Play
