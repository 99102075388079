import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Clearfix } from '../../../components'
import ClearIcon from '@material-ui/icons/Clear'
import { Stage, Layer, Image as KonvaImage, Line, 
//	Text 
} from 'react-konva'
import { updateDrawingData, clearDrawingData } from '../../../utils/firebaseGameHostAndPlayUtils'

const QuestionImageArea = styled.div`text-align: center; width: 100%;`
const Main = styled.div`width: 100%; margin: 0 auto; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center;`

const ButtonsArea = styled.div`cursor: pointer; z-index: 100; float: right; width: 100px;
	@media (max-width: 767px) {
		width: 100%;
	}`
const ClearButton = styled(ClearIcon)` float: right;
	@media (max-width: 767px) {
		display: none;
	}
`

function QuestionImage(props) {
	const [question, setQuestion] = useState(props.question)

	const [img, setImg] = useState()
	const [brushColor, setBrushColor] = useState('red')
	const [brushRadius, setBrushRadius] = useState(3)

	const [stageWidth, setStageWidth] = useState()
	const [stageHeight, setStageHeight] = useState()

	const [tool] = useState('pen')
	const [lines, setLines] = useState([])
	const isDrawing = useRef(false)

	let image	

	useEffect(() => {
		setQuestion(props.question)

		// console.log('props.question: ', props.question)
		
		image = new window.Image()
		image.src = props.question.questionImageURL
		image.addEventListener('load', handleImageLoad)

		return function cleanup () {
			image.removeEventListener('load', handleImageLoad)
		}
	}, [props.question.id])

	useEffect(() => { 
		setBrushColor(props.brushColor)
	}, [props.brushColor])

	useEffect(() => {
		setBrushRadius(props.brushRadius)
	}, [props.brushRadius])

	useEffect(() => {
		// console.log('EFFECT FOR ANY => props.question.drawing, img, stageWidth, stageHeight')
		updateDrawingsWithScaling()
	}, [props.question.drawing, img, stageWidth, stageHeight])

	const updateDrawingsWithScaling = () => {
		// console.log('DRAWING UPDATE ENTERED')
		if(img && stageWidth && stageHeight) {
			if (props.question.drawing && props.question.drawing.length > 0 && props.question.drawingAreaSize) {
				// console.log('DRAWING UPDATE STARTED')
				let hostDrawingHeight = props.question.drawingAreaSize.height
				let hostDrawingWidth = props.question.drawingAreaSize.width
	
				let xScaleRatioFromHost = stageWidth / hostDrawingWidth
				let yScaleRatioFromHost = stageHeight / hostDrawingHeight
	
				// console.log('xScaleRatioFromHost:', stageWidth, ' / ', hostDrawingWidth, ' = ', xScaleRatioFromHost)
				// console.log('xScaleRatioFromHost:', xScaleRatioFromHost)
				// console.log('yScaleRatioFromHost:', yScaleRatioFromHost)
	
				// console.log('props.question.drawing: ', props.question.drawing)
				let tempLines = [...props.question.drawing]
				// console.log('tempLines: ', tempLines)
				
				for (let i = 0; i < tempLines.length; i++) {
					let tempLine = tempLines[i]
					// let tempLine1 = [...tempLines[i].points]
					// console.log('=========== START tempLine ', i, ' : ', tempLine1[0])
					for (let j = 0; j < tempLine.points.length; j++) {
						let point = tempLine.points[j]
						// console.log('point:', tempLine)
						if (j % 2 === 0) {						
							point = point * xScaleRatioFromHost
						} else {
							point = point * yScaleRatioFromHost
						}
						tempLine.points[j] = point
					}
					// console.log('=========== END tempLine ', i, ' : ', tempLine.points[0])
					tempLines[i] = tempLine
				}
				setLines(tempLines)
			} else if (props.question.drawing !== lines) {
				setLines([])
			}
		}
	}
	const handleImageLoad = () => {
		setImg(image)

		const originalWidth = image.width
		const originalHeight = image.height
		// console.log('originalWidth:', originalWidth)
		// console.log('originalHeight:', originalHeight)
		
		const avaiableWidth = window.innerWidth
		const avaiableHeight = window.innerHeight * (props.question.qType === 'imageContent' || props.gamePhase === 'show_question' ? 0.8 : 0.4)
		// console.log('window.innerWidth: ', avaiableWidth)
		// console.log('window.innerHeight: ', avaiableHeight)

		const xScaleRatio = avaiableWidth / originalWidth
		const yScaleRatio = avaiableHeight / originalHeight
		// console.log('xScaleRatio: ', xScaleRatio)
		// console.log('yScaleRatio: ', yScaleRatio)
		
		const scaleRatio = Math.min(xScaleRatio, yScaleRatio)
		// console.log('scaleRatio: ', scaleRatio)

		let imgHeight = originalHeight * scaleRatio
		let imgWidth = originalWidth * scaleRatio

		// console.log('imgHeight: ', imgHeight)
		// console.log('imgWidth: ', imgWidth)
		setStageHeight(imgHeight)
		setStageWidth(imgWidth)

		// console.log('IMAGE LOAD ???')
		updateDrawingsWithScaling()
	}
	const handleMouseDown = (e) => {
		isDrawing.current = true
		const pos = e.target.getStage().getPointerPosition()
		setLines([...lines, { tool, points: [pos.x, pos.y] }])
	}
	
	const handleMouseMove = (e) => {
		if (!isDrawing.current) { // no drawing - skipping
			return
		}
		const stage = e.target.getStage()
		const point = stage.getPointerPosition()
		
		let lastLine = lines[lines.length - 1]		
		lastLine.points = lastLine.points.concat([point.x, point.y]) // add point
	
		lines.splice(lines.length - 1, 1, lastLine) // replace last
		setLines(lines.concat())
	}
	
	const handleMouseUp = () => {
		isDrawing.current = false
		// console.log('lines: ', lines)
		updateDrawing()
	}

	const updateDrawing = () => {
		if (props.gameFunc) { // Play cannot update game
			const drawingData = { ...props.question, drawingAreaSize: {width: stageWidth, height: stageHeight}, drawing: lines }
			updateDrawingData(drawingData, props.gameKey, props.question.id)
			// fire.database().ref('/game_instances/' + props.gameKey + '/quiz/questions/' + parseInt(props.question.id - 1)).set(drawingData, function (error) { if (error) { } })
		}
	}

	const clearDrawing = () => {
		if (props.gameFunc) { // Play cannot update game
			const drawingData = { ...props.question, drawingAreaSize: {width: stageWidth, height: stageHeight}, drawing: [] }
			clearDrawingData(drawingData, props.gameKey, props.question.id)
			// fire.database().ref('/game_instances/' + props.gameKey + '/quiz/questions/' + parseInt(props.question.id - 1)).set(drawingData, function (error) { if (error) { } })
		}
	}
	
    return (
        <QuestionImageArea>
			{
				question && img &&
					<Main>						
						<Stage width={stageWidth} height={stageHeight} style={{margin: 'auto'}}
							onMouseDown={(e) => handleMouseDown(e)}
							onMousemove={(e) => handleMouseMove(e)}
							onMouseup={() => handleMouseUp()}
						>
							<Layer style={{margin: 'auto', width: stageWidth, height: stageHeight }}>
								<KonvaImage image={img}  width={stageWidth} height={stageHeight}  />
							</Layer>
							<Layer>
								{/* <Text text={props.question.question} x={5} y={30} /> */}
								{
									lines && lines.length > 0 && lines.map((line, i) => (
										<Line key={i}
											points={line.points}
											stroke={brushColor ? brushColor : 'red'}
											strokeWidth={brushRadius ? brushRadius : 3 }
											tension={0.5}
											lineCap="round"
											globalCompositeOperation={
												line.tool === 'eraser' ? 'destination-out' : 'source-over'
											}
										/>
									))
								}
							</Layer>
						</Stage>
						<ButtonsArea>
							<ClearButton color="action" onClick={() => { setLines([]); clearDrawing()  }} title="Temizle" />
						</ButtonsArea>
						<Clearfix />
					</Main>
			}
		</QuestionImageArea>
    )
}

export default QuestionImage
