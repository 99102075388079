import React, { Component } from 'react'
import styled from 'styled-components'
import { CountdownAnimation } from '../../../components'

const StartMessage = styled.div`font-size: 60px; margin-top: 80px; font-weight: bold; width: 100%; text-align: center;`

let interval
class PhaseStarting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            counter: this.startCounter(),
        }
        this.nextPhase = this.nextPhase.bind(this)
	}
	
    startCounter() {
        let that = this
        let counter = 5

        interval = setInterval(function () {
            counter--
            that.setState({ counter: counter })
			
			if (counter === 0) {
                that.nextPhase()
                clearInterval(interval)
            }
        }, 1000)
        return 5
    }

    componentWillUnmount() {
        clearInterval(interval)
    }

    nextPhase() {
        this.props.gameFunc.update({ phase: "awaiting_question" })
    }
	
	render() {
        return (
            <div>
                <StartMessage>Başlıyoruz...</StartMessage>
                <CountdownAnimation speed="slow" />
            </div>
        )
    }
}

export default PhaseStarting
