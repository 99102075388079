import React from 'react'
import PhaseConnection from './PhaseConnection'
import PhaseStarting from './PhaseStarting'
import PhaseAwaitingQuestion from './PhaseAwaitingQuestion'
import PhaseShowQuestion from './PhaseShowQuestion'
import PhaseShowContent from './PhaseShowContent'
import PhaseAnswer from './PhaseAnswer'
import PhaseFinalResult from './PhaseFinalResult'
import PhaseResultQuestion from './PhaseResultQuestion'
import { saveAnswerOnPlay } from '../../../utils/firebaseGameHostAndPlayUtils'

function Quiz(props) {
    
    const saveAnswer = (answer) => {
		let currentQuestionId = props.game.quiz.questions[props.game.quiz.currentQuestion].id
		
        saveAnswerOnPlay(props.game.key, props.playerKey, currentQuestionId, answer, (error) => {
            if (error) {
                let snack = {
                    variant: "error",
                    message: "Unexpected internal error"
                }
                props.showSnackbar(snack)
            }
        })
    }
    
    let lastPhase = props.game.phase === "final_result" || props.game.phase === "end"
    return (
        <div className="play-container">
            { (props.game.phase === "connection" || !props.playerKey)  && <PhaseConnection game={props.game} addPlayer={props.createPlayer} playerKey={props.playerKey} />}
            { (props.game.phase === "starting" && props.playerKey) && <PhaseStarting game={props.game} updatePlayer={props.updatePlayer} />}
            { (props.game.phase === "awaiting_question" && props.playerKey) && <PhaseAwaitingQuestion game={props.game} updatePlayer={props.updatePlayer} />}

            { (props.game.phase === 'show_content' && props.playerKey) && <PhaseShowContent game={props.game} updatePlayer={props.updatePlayer} />}

            { (props.game.phase === "show_question" && props.playerKey) && <PhaseShowQuestion game={props.game} updatePlayer={props.updatePlayer} />}
            { (props.game.phase === "answer" && props.playerKey) && <PhaseAnswer game={props.game} saveAnswer={saveAnswer} playerKey={props.playerKey} />}
            { (props.game.phase === "result_question" && props.playerKey) && <PhaseResultQuestion game={props.game} updatePlayer={props.updatePlayer} playerKey={props.playerKey} />}
            { (lastPhase && props.playerKey) && <PhaseFinalResult game={props.game} updatePlayer={props.updatePlayer} playerKey={props.playerKey} />}
        </div>
    )
}

export default Quiz
