import React from 'react'
import styled from 'styled-components'
import QuestionContent from '../shared/QuestionContent'
import { H1 } from '../../../components'

const QuestionSection = styled.div`height: 88vh; text-align: center; overflow: auto; vertical-align: middle; display: table-cell; width: 100vw; padding: 10px;
	@media (max-width: 1016px) {
		height: 84vh; 
	}
`

function PhaseShowQuestion(props) {	
	let currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
	if (props.game.quiz.remoteMode) {
		return (
			<div>
				{ currentQuestion &&
					<QuestionSection>
						<QuestionContent question={currentQuestion} 
							imageBrushColor="red"
							videoPlaying={false} videoUseMaxScreen={true} onVideoEnd={null}
							gamePhase={props.game.phase}
						/>
					</QuestionSection>
				}
			</div>
		)
	} else {
		return (
			<H1>Soru için ana ekrana bakın!</H1>
		)
	}
	
}

export default PhaseShowQuestion
