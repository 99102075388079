import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import Podium from '../shared/Podium'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function PhaseFinalResult(props) {
    const [expanded, setExpanded] = useState()
        
    const handleChange = panel => (event, _expanded) => {
        setExpanded(_expanded ? panel : false)
    }
    
    const getPlayerAnswerData = () => {
        let answerDataList = []
        let currentPlayer = props.game.players[props.playerKey]

        for (let i = 0; i < props.game.quiz.questions.length; i++) {
            let answerData = {
                question: {},
                score: 0,
                time: 0,
                answer: '',
            }
            let question = props.game.quiz.questions[i]

            if (currentPlayer.answers[question.id]) {
                answerData.score = currentPlayer.answers[question.id].score
                answerData.answer = currentPlayer.answers[question.id].answer
                answerData.time = currentPlayer.answers[question.id].answerTime
            } else {
                answerData.score = 0
                answerData.answer = "no answer"
                answerData.time = 0
            }
            answerData.question = question

            answerDataList.push(answerData)
        }
        return answerDataList
    }

    if (props.game.quiz.remoteMode) {
        let playerData = getPlayerAnswerData();
        return (
            <div className="phase-container">
                
                <div className="quiz-top-section">
                    <Typography variant="h5">Program sona erdi</Typography>
                </div>

                <div className='quiz-middle-section'>
                    {/* <Typography variant="subtitle1">Şu sonuç ile bitirdiniz.
                        <span className="dynamic-text">2nd</span> with a score of  <span className="dynamic-text">560</span>
                    </Typography> */}

                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Sıralama Tablosu</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <Podium game={props.game} />
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Sorulara verdiğiniz cevaplar</Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails style={{display:'block'}}>
                            {
                                playerData.map((data, index) =>
                                    <ExpansionPanel key={data.question.id} expanded={expanded === data.question.id} onChange={handleChange(data.question.id)}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="subtitle1">{data.question.question}</Typography>
                                            <Typography variant="subtitle2">{data.score}pt</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>Cevabınız: {data.answer}</Typography>
                                            <Typography>Cevaplama Süreniz: {data.time/1000}sec</Typography>

                                            {/* <Typography variant="subtitle1" align="left">Doğru Cevaplar</Typography>
                                            
                                            <List>
                                                {data.question.correctAnswers.map((answer, index) =>
                                                    <ListItem key={answer}>
                                                        <ListItemText
                                                            primary={answer}
                                                        />
                                                    </ListItem>
                                                )}
                                            </List>
                                            <Typography variant="subtitle1" align="left">Yanlış Cevaplar</Typography> */}
                                            
                                            {/* <List>
                                                {
                                                    data.question.wrongAnswers.map((answer, index) =>
                                                        <ListItem key={answer}>
                                                            <ListItemText
                                                                primary={answer}
                                                            />
                                                        </ListItem>
                                                    )
                                                }
                                            </List> */}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div className="quiz-bottom-section"></div>
            </div>
        )
    } else {
        return (
            <div className="phase-container">
                <div className="quiz-top-section"></div>
                <div className='quiz-middle-section'>
                    <Typography variant="h5">Sonuçlar için ana ekrana bakın</Typography>
                </div>
                <div className="quiz-bottom-section"></div>
            </div>
        )
    }
}

export default PhaseFinalResult
