import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import AnswerOptionList from '../shared/AnswerOptionList'
import AnswerCounter from '../shared/AnswerCounter'
import QuestionContent from '../shared/QuestionContent'
import { Timer, H1 } from '../../../components'

const AnswersSectionLocal = styled.div`height: calc(100vh - 50px); text-align: center;`

// For remote mode
const QuestionText = styled.div`width: 100%; text-align: center; margin-top: 10px; font-size: 64px; color: black;`
const CounterSection = styled.div`text-align: center; overflow: auto; position: absolute; top: 0px; right: 0px; width: 100%;`

const QuestionSection = styled.div`height: 54vh; text-align: center; overflow: auto; width: 100%;`
const AnswerCounterSection = styled.div`text-align: center; overflow: auto; width: 100%;`
const AnswersSection = styled.div`height: 40vh; margin-top: 10px; position: absolute; bottom: 0; left: 0; width: 100%;
	@media (max-width: 767px) {
		height: 37vh;
	}
`

const YourAnswerArea = styled.div`margin: auto; margin-top: 100px; font-size: 20px;`
const YourAnswer = styled.div`font-size: 36px; font-weight: bold; width: 95%; margin: auto; color: red;`

let interval

function PhaseAnswer(props) {
	const [counter, setCounter] = useState(0)
	const [isTimeLimited, setIsTimeLimited] = useState(true)
	const [startTime, setStartTime] = useState()
	const [question, setQuestion] = useState({})
	const [hasAnswered, setHasAnswered] = useState(false)
	const [selectedAnswer, setSelectedAnswer] = useState('')

	useEffect(() => {
		const _question = props.game.quiz.questions[props.game.quiz.currentQuestion]

		setQuestion(_question)
		setStartTime(Date.now())
		setIsTimeLimited(props.game.quiz.timeLimit)

		if (props.game.quiz.timeLimit) {
			let _counter = _question.timeLimit
			let totalTime = _question.timeLimit * 1000
			var start = new Date().getTime()
			interval = setInterval(() => {
				var now = new Date().getTime()
				var elapsed = now - start
				_counter = (totalTime - elapsed) / 100
				if (_counter < 0) {
					_counter = 0
				}

				setCounter(_counter)
				
				if (_counter <= -5) {					
					clearInterval(interval)
				}
			}, 100)
		}

		return () => {
			clearInterval(interval)
		}
	}, [])

    const answerQuestion = (answer) => {
		setSelectedAnswer(answer)
		setHasAnswered(true)
		const answerTime = Date.now() - startTime
		let correct = false
		if (question.aType === 'multiple-image') {
			question.correctAnswersImages.map(cAnswer => {
				if (cAnswer.url === answer) {
					correct = true
				}
				return correct
			})
		} else {
			correct = question.correctAnswers.indexOf(answer) > -1
		}
		
		let score = 0
		
		if (correct) {
			if (question.point) {
				score += question.point
			} else {
				score += 1000
			}

			if (props.game.quiz.timeLimit && question.timeLimit) {
				const timeFactorUsed = answerTime / (question.timeLimit * 1000)
				score = Math.floor(score - (score * Math.min(timeFactorUsed, 0.5)) ) // decrease used time from question point
			}
		}

		const playerAnswer = {
			answer,
			score,
			answerTime,
			questionId: question.id,
			isCorrect: correct
		}
		props.saveAnswer(playerAnswer)
    }
	
	let currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
	
	if (props.game.quiz.remoteMode) {
		if (hasAnswered) {
			let qfontSize = currentQuestion.question.length < 30 ? '48px': (currentQuestion.question.length < 60 ? '36px': (currentQuestion.question.length < 90 ? '24px': (currentQuestion.question.length < 120 ? '18px': '14px' ) ) )
			return (
				<div>
					<QuestionText style={{fontSize: qfontSize}}>
						{currentQuestion.question}
					</QuestionText>                        

					<YourAnswerArea>Tebrikler, Cevabın alındı.
						<YourAnswer>
							{
								(question.aType === 'multiple-image') ? 
									<img src={selectedAnswer} style={{width: '250px'}} alt="Your answer" />
								:
									<span>{selectedAnswer}</span>
							}
						</YourAnswer>
					</YourAnswerArea>
					
					<CounterSection>
						{isTimeLimited && <Timer startValue={currentQuestion.timeLimit * 10} value={counter} text={Math.ceil(counter / 10).toString()} />}						
					</CounterSection>
					
					<AnswerCounterSection>
						<H1>Sürenin bitmesi bekleniyor.</H1>							
						<AnswerCounter game={props.game}/>
					</AnswerCounterSection>
				</div>
			)
		}

		return (
			<div>
				{
					currentQuestion &&
						<div>
							<QuestionSection>
								<QuestionContent question={currentQuestion} videoPlaying={false} onVideoEnd={null} videoMaxHeight="240px" />
							</QuestionSection>

							<CounterSection>
								{isTimeLimited && <Timer startValue={currentQuestion.timeLimit * 10} value={counter} text={Math.ceil(counter / 10).toString()} />}
							</CounterSection>

							<AnswersSection>
								<AnswerOptionList question={currentQuestion} answerQuestion={(a) => answerQuestion(a)} />
							</AnswersSection>							
						</div>
				}
			</div>
		)
	}
	
	if (hasAnswered) {
		return (
			<div>
				<YourAnswerArea>Cevabınız: 
					<YourAnswer>
						{
							(question.aType === 'multiple-image') ? 
								<img src={selectedAnswer} style={{width: '250px'}} alt="Your answer" />
							:
								<span>selectedAnswer</span>
						}
					</YourAnswer>
				</YourAnswerArea>
			</div>
		);
	} 
	return (
		<AnswersSectionLocal>
			<AnswerOptionList question={currentQuestion} answerQuestion={(a) => answerQuestion(a)} containerClassName="full-height" />
		</AnswersSectionLocal>
	)
}
export default PhaseAnswer
