import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2'

import StarIcon from '@material-ui/icons/Star'
import TripOriginIcon from '@material-ui/icons/TripOrigin'
import FilterVintageIcon from '@material-ui/icons/FilterVintage'
import CropSquareIcon from '@material-ui/icons/CropSquare'

const Container = styled.div`text-align: center; padding-top: 5px; height: 180px; max-width: 400px;
	@media (max-width: 767px) {
		width: 100%;
		height: 120px;
	}
`

const PUBLIC_PATH = process.env.PUBLIC_URL

const answerStyles = [
	{
		icon: <CropSquareIcon style={{fontSize: 'inherit'}} />,
		color: '#d89e00',
	}, {
		icon: <StarIcon style={{fontSize: 'inherit'}} />,
		color: '#1368ce',
	}, {
		icon: <TripOriginIcon style={{fontSize: 'inherit'}} />,
		color: '#26890c',
	}, {
		icon: <FilterVintageIcon style={{fontSize: 'inherit'}} />,
		color: '#e21b3c',
	},
]

function AnswerChart(props) {
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [{
			data: [],
			label: '',
			backgroundColor: [],
			borderWidth: 1,
		}],
	})
	
	const getAnswerData = () => {
		const answerData = {
			data: [],
			topPlayer: {
				score: 0,
				playerKey: '',
			},
			correctAnswers: [],
		}
		
		const playerAnswers = []
		const playerKeys = props.game.players ? Object.keys(props.game.players) : []
		const currentQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
		
		for (let i = 0; i < playerKeys.length; i++) {
			const player = props.game.players[playerKeys[i]]
			if (!player.answers) {
				continue
			}
			const answer = player.answers[currentQuestion.id]
			if (answer) {
				playerAnswers.push(answer.answer)
				
				if (answer.score > answerData.topPlayer.score) {
					answerData.topPlayer.score = answer.score;
					answerData.topPlayer.playerKey = player.key;
				}
			}
		}

		for (let j = 0; j < currentQuestion.answers.length; j++) {
			let nrOfAnswers = 0
			for (let i = 0; i < playerAnswers.length; i++) {
				if (currentQuestion.answers.indexOf(playerAnswers[i]) === j) {
					nrOfAnswers++
				}
			}
			answerData.data.push(nrOfAnswers)

			if (currentQuestion.correctAnswers.indexOf(currentQuestion.answers[j]) > -1) {
				answerData.correctAnswers.push(j)
			}

			currentQuestion.correctAnswersImages.map((answerImageData) => {
				if (answerImageData.url === currentQuestion.answers[j]) {
					answerData.correctAnswers.push(j)
				}
				return null
			})
		}
		// console.log('answerData: ', answerData)
		return answerData
	}

	const getChartData = () => {
		const tempChartData = {
			labels: [],
			datasets: [{
				data: [],
				label: '',
				backgroundColor: [],
				borderWidth: 1,
			}],
		}

		const data = getAnswerData()
		tempChartData.datasets[0].borderColor = []
		tempChartData.datasets[0].data = data.data
		
		for (let i = 0; i < data.data.length; i++) {
			tempChartData.labels.push('')
			tempChartData.datasets[0].backgroundColor.push(answerStyles[i].color)
		}
		if (tempChartData !== chartData) {
			setChartData(tempChartData)
		}
	}

	const getChartHeight = () => {
		const answerData = getAnswerData()
		const topvalue = Math.max(...answerData.data)
		const incrementHeightPerAnswer = 25
		const minHeight = 65
		const maxHeight = 300
		const height = minHeight + (topvalue * incrementHeightPerAnswer)
		if (height > maxHeight) {
			return maxHeight
		}
		return height
	}

	const getChartOptions = () => {
		const options = {
			maintainAspectRatio: false,
			showTooltips: true,
			responsiveAnimationDuration: 1000,
			animation: {
				easing: 'easeOutQuad',
				duration: '1000',
				onProgress (animation) {
					let answerData = getAnswerData()
					animation.animationObject.onAnimationProgress = function () {
						let ctx = this.chart.ctx
						let chart = this.chart
						ctx.textAlign = "center"
						ctx.fillStyle = "black"
						ctx.textBaseline = "bottom"
						ctx.font = "24px Nunito"

						let stepratio = animation.animationObject.currentStep / animation.animationObject.numSteps

						let meta = chart.getDatasetMeta(0)
						if (!meta.hidden) {
							meta.data.forEach(function (element, index) {
								let dataString = Math.floor(chart.data.datasets[0].data[index] * stepratio)

								let position = element.tooltipPosition()
								ctx.fillStyle = answerStyles[index].color
								ctx.fillText(dataString, position.x, position.y - 2)
								ctx.beginPath()
								ctx.rect(position.x - element._view.width / 2, chart.height - 30, element._view.width, 24)
								ctx.fill()

								if (answerData.correctAnswers.indexOf(index) > -1) {
									let img = new Image()
									img.src = PUBLIC_PATH + "/baseline-done_outline-24px.svg"
									img.height = 100
									img.width = 100
									let ypos = chart.height - 31
									ctx.drawImage(img, position.x - 12, ypos)
								}
							})
						}
					}
				},
			},
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true,
					},
					display: false,
					beginAtZero: true,
				}],
				xAxes: [{
					display: false,
				}],
			},
			legend: {
				display: false,
			},
			tooltips: {
				enabled: true,
			},
			events: [],
			layout: {
				padding: {
					left: 0,
					right: 0,
					top: 25,
					bottom: 40,
				},
			},
		}
		return options
	}

	useEffect(() => {
		getChartData()
	}, [])

	
	if (chartData) {
		return (			
			<Container>
				<Bar
					data={chartData}
					height={getChartHeight()}
					options={getChartOptions()}
				/>
			</Container>
		)
	}
	return (
		<div></div>
	)
}

export default AnswerChart
