import React, { useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { AuthContext } from '../../data/context/AuthProvider'
import { LogoImg, BaseInput, BaseButton, BaseLink, Centered, EmptySpace, FormElementLabel } from '../../components'
import { firebaseAuth, firestore, getBrandData } from '../../utils'

const PageTitle= styled.div`font-weight: bold; font-size: 18px; margin-top: 20px; margin-bottom: 10px;`
const FormArea = styled(Grid)`border: solid 1px gainsboro; border-radius: 8px; padding: 30px;
	-webkit-box-shadow: inset 0 0 6px rgba(30, 30, 30, 0.25);
	@media (max-width: 767px) {
		padding: 15px;
	}
`
function LoginComponent(props) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [submitData, setSubmitData] = useState()

	const [, setAuthContextData] = useContext(AuthContext)

	const loginApp = () => {
		if (email && password) {
			setSubmitData({email: email, password: password})
			firebaseAuth.signInWithEmailAndPassword(email, password)
				.then((response) => {
					localStorage.setItem('email', response.user.email)
					let userData = {}
					firestore.collection('users').where("email", "==", email).get().then(snapshot1 => {
						if (snapshot1.docs.length === 1) {
							userData = snapshot1.docs[0].data()
							setAuthContextData({
								user: response.user.email,
								email: response.user.email,
								name: userData.name ? userData.name : '',
								roles: userData.roles ? userData.roles : [],
								lessons: userData.lessons ? userData.lessons : [],
								status: userData.status ? userData.status : 'disabled',
							})
						} else {
							setAuthContextData({
								user: response.user.email,
								email: response.user.email,
								name: '',
								roles: [],
								lessons: [],
								status: 'disabled',
							})
						}
						console.log('userData: ', userData)
						console.log('userData: ', userData)
						props.onLogin(userData)
					})					
				})
				.catch((error) => {
					console.log({result: 'failed', error: error})
				})
		}
	}	
	return (
		<div style={{width: '95%', margin: 'auto'}}>
			<Centered>
				<div style={{display: (props.showLogoPart ? 'block' : 'none')}}>
					<EmptySpace marginTop='30px' />
					<BaseLink to="/">
						<LogoImg src={`/images/${getBrandData().logoFolder}/logo.png`} float="none" />
					</BaseLink>
				</div>
				
				<PageTitle>KULLANICI GİRİŞİ</PageTitle>
				{/* <div>Lütfen kullanıcı bilgilerinizi girerek oturum açınız.</div> */}
			</Centered>
			<Grid container justify="space-evenly" alignItems="center">
				<FormArea item xs={12} sm={8} md={6}>
					<EmptySpace marginTop='30px' />	
					<FormElementLabel>E-Posta Adresi</FormElementLabel>
					<BaseInput value={email} onChange={(newValue) => {setEmail(newValue)} } showAsRequired={submitData && !email} maxlength="80" />
					<EmptySpace marginTop='30px' />

					<FormElementLabel>Şifre</FormElementLabel>						
					<BaseInput type="password" value={password} onChange={(newValue) => {setPassword(newValue)} } showAsRequired={submitData && !password} />
					<EmptySpace marginTop='30px' />
					<BaseButton type="button" value="GİRİŞ" onClick={() => loginApp()} style={{float: 'right', width: '200px'}} />
					<EmptySpace marginTop='30px' />
				</FormArea>
			</Grid>
		</div>
	)
}

export { LoginComponent }
