import React, { useState } from 'react'
import styled  from 'styled-components'
import Switch from '@material-ui/core/Switch'
import { H1, FormLine, FormElementLabel, BaseButton } from '../../../components'

const HostPage = styled.div`margin: 10px; max-width: 400px; width: 90%; margin-left: auto; margin-right: auto;`

function PhaseSetup(props) {
    const [answerStreak, setAnswerStreak] = useState(true)
    const [nameGenerator, setNameGenerator] = useState(false)
    const [remoteMode, setRemoteMode] = useState(true)
    const [randomizeQuestionOrder, setRandomizeQuestionOrder] = useState(false)
    const [autoPlayQuestions, setAutoPlayQuestions] = useState(false)

    // const createTeamGame = () => {
	// 	startGame(true)
	// }
	
    const createClassicGame = () => {
		startGame(false)
	}
	
    const startGame = (teamMode) => {
        let game = {}
        let quiz = props.game.quiz
        quiz.answerStreak = answerStreak
        quiz.nameGenerator = nameGenerator
        quiz.remoteMode = remoteMode
        quiz.useTeams = teamMode
        quiz.autoPlayQuestions = autoPlayQuestions
        quiz.randomizeQuestionOrder = randomizeQuestionOrder
        quiz.currentQuestion = 0

        game.quiz = quiz
        game.phase = "connection"
        game.status = "IN_PROGRESS"
        props.gameFunc.update(game)
    }

    
    return (
        <HostPage>
            <H1>AYARLAR</H1>
            
            <FormLine>
                <BaseButton type="button" onClick={() => createClassicGame()} value="KLASİK MOD" height="48px" fontSize="24px" />
                {/* <BaseButton type="button" onClick={() => createTeamGame()} value="TAKIM MODU" height="48px" shadowColor="gray" /> */}
            </FormLine>

            <FormLine style={{display: 'none'}}>
                <FormElementLabel>Katılımcıların isimlerini otomatik ata</FormElementLabel>                    
                <Switch checked={nameGenerator} onChange={(e) => setNameGenerator(e.target.checked)} value="nameGenerator" />
            </FormLine>

            <FormLine style={{display: 'none'}}>
                <FormElementLabel>Remote playmode</FormElementLabel>                    
                <Switch checked={remoteMode} onChange={(e) => setRemoteMode(e.target.checked)} value="remoteMode" />
            </FormLine>

            <FormLine style={{display: 'none'}}>
                <FormElementLabel>Use answer score streak</FormElementLabel>                    
                <Switch checked={answerStreak} onChange={(e) => setAnswerStreak((e.target.checked))} value="answerStreak" />
            </FormLine>
                            
            <FormLine style={{display: 'none'}}>
                <FormElementLabel>Randomize question order</FormElementLabel>                    
                <Switch checked={randomizeQuestionOrder} onChange={(e) => setRandomizeQuestionOrder((e.target.checked))} value="randomizeQuestionOrder" />
            </FormLine>
            
            <FormLine style={{display: 'none'}}>
                <FormElementLabel>Auto play questions</FormElementLabel>                    
                <Switch checked={autoPlayQuestions} onChange={(e) => setAutoPlayQuestions((e.target.checked))} value="autoPlayQuestions" />
            </FormLine>
        </HostPage>
    )
}

export default PhaseSetup
