import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { getGameInstancesForTemplate, createNewGameInstance, deleteGameInstance } from '../../../utils/firebaseGameCreationUtils'
import { DrawerContentArea, BaseInput } from '../../../components'
import LessonListItem from './LessonListItem'

const ButtonArea = styled.div`position: -webkit-sticky; position: sticky; top: 0px; zIndex: 10; background-color: white; text-align: center; height: 60px;`

function LessonList(props) {
	const [openNewDialog, setOpenNewDialog] = useState(false)
	const [gameInstances, setGameInstances] = useState([])
	const [newInstanceName, setNewInstanceName] = useState('')

	useEffect(() => {
		getGameInstances()
	}, [])

	const getGameInstances = () => {
        getGameInstancesForTemplate(props.game.templateKey, (returnedGameInstanceList) => {
            returnedGameInstanceList.sort(function(a,b) {
                if (a.created < b.created) return 1
                if (a.created > b.created) return -1
                return 0
            })
            setGameInstances(returnedGameInstanceList)
        })
    }
	
	const deleteInstance = (gameInstanceKey) => {
		console.log('delete instance: ', )
		deleteGameInstance(gameInstanceKey, (error) => {
			if (error) {
				console.log('Game Instance Delete Error: ', error)
			} else {
				getGameInstances()
			}
		})
    }

	const createGameInstance = (_gameTemplate) => {
		createNewGameInstance(_gameTemplate.templateKey, newInstanceName, (error) => {
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
            } else {
				setNewInstanceName('')
				getGameInstances()
				setOpenNewDialog(false)
            }
		})
    }

    return (
		<div>
			<DrawerContentArea style={{height: isMobile ? 'calc(100vh - 70px)': 'calc(100vh - 160px)', padding: '0px', margin: '0px'}} >
				<ButtonArea>
					<Fab color="primary" aria-label="add" size="small">
						<AddIcon onClick={() => setOpenNewDialog(true)} />
					</Fab>
				</ButtonArea>
					{
						gameInstances && gameInstances.length > 0 &&
							gameInstances.map((gameInstance) => gameInstance &&
								<LessonListItem gameInstance={gameInstance} showDelete={false} key={gameInstance.gameId}
									selectedInstance={props.selectedInstance}
									deleteInstance={(i) => deleteInstance(i)} 
									selectInstance={(i) => props.setActiveLesson(i)}
								/>
							)
					}
			</DrawerContentArea>

			<Dialog open={openNewDialog} onClose={() => setOpenNewDialog(false)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Yeni Ders Ekle</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Derse bir isim belirleyin.
					</DialogContentText>
					<BaseInput autoFocus id="name" value={newInstanceName} onChange={(newValue) => setNewInstanceName(newValue)} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenNewDialog(false)} color="primary">
						IPTAL
					</Button>
					<Button onClick={() => createGameInstance(props.game)} color="primary">
						EKLE
					</Button>
				</DialogActions>
			</Dialog>
		</div>
    )
}

export default LessonList
