import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { ConnectedUsers } from '../shared/ConnectedUsers'
import { H1, EmptySpace } from '../../../components'
import { LoginComponent } from '../../../PageBlocks/user/LoginComponent'
import { GuestUserName } from '../../user/GuestUserName'

const PlayPage = styled.div`margin: 10px; width: 98%; margin-left: auto; margin-right: auto; text-align: center;`
const UserWelcomeMessage = styled.div`font-size: 28px; font-weight: bold; margin: 10px;`
const PlayerName = styled.span`color: red; font-size: 48px; line-height: 48px;`

const UserInfo = styled.div`text-align: center; height: 90vh; line-height: 40vh;`


const WaitingMessage = styled.div`width: 100%; text-align: center; font-size: 18px;`


function PhaseConnection(props) {
    const [name, setName] = useState('')
    
    const createPlayer = () => {
        if (name.length > 2) {
            let player = {
                name: name.trim(),
                score: 0,
            }
            props.addPlayer(player)
        } else {
            alert('Belirlediginiz isim en az 3 karakter olmalıdır.')
        }
    }
    
    let players = props.game.players
    let playerName = ''
    
    if (players && props.playerKey && props.playerKey.length > 0 && players[props.playerKey]) {
        playerName = players[props.playerKey].name
    }
    
    
    if (props.game.quiz.remoteMode) {
        return (
            <PlayPage>
                {
                    playerName &&
                        <div>
                            <UserWelcomeMessage>
                                Hoşgeldin <PlayerName>{playerName}</PlayerName>
                            </UserWelcomeMessage>

                            <ConnectedUsers game={props.game} animate={true} />
                            
                            <WaitingMessage>Dersin başlaması bekleniyor</WaitingMessage>
                        </div>
                }
                {
                    !playerName &&
                        <div>
                            <LoginComponent onLogin={(user) => { createPlayer(user.name)} } showLogoPart={false}></LoginComponent>
                            
                            <EmptySpace marginTop='20px' />
                            <div>veya</div>
                            <EmptySpace marginTop='20px' />

                            <GuestUserName createPlayer={(n) => createPlayer()} updateName={(n) => setName(n)} name={name} />
                        </div>
                }
            </PlayPage>
        )
    } else {
        return (
            <PlayPage>
                {
                    playerName &&
                        <UserInfo>
                            <H1>Merhaba <span className="dynamic-text">{playerName}</span>!</H1>
                            <Typography variant="subtitle1"> Ana ekranı takip edin. Adınız ekranda görünüyor olmalı.</Typography>
                        </UserInfo>
                }
                {
                    !playerName &&
                        <GuestUserName createPlayer={(n) => createPlayer()} updateName={(n) => setName(n)} name={name} />
                }
            </PlayPage>
        )
    }
}

export default PhaseConnection
