import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { BaseButton } from '../../../components'
import QuestionContent from '../shared/QuestionContent'


const QuestionSection = styled.div`height: 90vh; text-align: center;`

const NextButtonArea = styled.div`margin-right: 10px; margin-bottom: 25px;`
const NextQuestionButton = styled(BaseButton)` width: 250px; position: absolute; bottom: 5px; height: 60px; float: right; right: 5px;
	@media (max-width: 767px) {
		width: 150px;
		height: 40px;
	}
`

function PhaseShowContent(props) {
	const [question, setQuestion] = useState('')
	
	useEffect(() => {
		const _question = props.game.quiz.questions[props.game.quiz.currentQuestion]
		if (_question) {
			setQuestion(_question)
		}
	}, [])


	const goNextQuestion = () => {
		const game = {}
		game.quiz = props.game.quiz
		game.quiz.currentQuestion = game.quiz.currentQuestion + 1
		game.phase = 'awaiting_question'
		props.gameFunc.update(game)
	}

	const finalizeQuiz = () => {
		props.gameFunc.update({ phase: 'final_result' })
	}

	
	const nextQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion + 1]
	const isLastQuestion = typeof nextQuestion === 'undefined'

	return (
		<div>
			{ question &&
				<QuestionSection>
					<QuestionContent question={question} 
						imageBrushColor="red"
						videoPlaying={true} onVideoEnd={null} videoControlType="host" videoUseMaxScreen={true} 
						gameFunc={props.gameFunc} gameKey={props.game.key}
					/>
				</QuestionSection>
			}

			<NextButtonArea>
				{isLastQuestion && <NextQuestionButton onClick={finalizeQuiz} value="TAMAMLA" type="button" height="50px" fontSize="24px" />}
				{!isLastQuestion && <NextQuestionButton onClick={goNextQuestion} value="DEVAM" type="button" height="50px" fontSize="24px" />}
			</NextButtonArea>
			
		</div>
	)
}

export default PhaseShowContent
