import React from 'react'

function BaseInput(props) {
	return (
		<input
			type={props.type ? props.type : "text"}
			onChange={(e) => { props.onChange(e.target.value)} }
			value={props.value}
			style={{
				border: ((props.showAsRequired && props.value.length === 0) ? 'solid 2px red' : 'solid 1px gainsboro'),
				width: props.width ? props.width : '100%',
				maxWidth : props.maxWidth ? props.maxWidth : '100%',
				borderRadius: '6px',
				height: props.height ? props.height : '32px',
				fontSize: props.fontSize ? props.fontSize : '18px',
				paddingLeft: '10px',
				color: props.color ? props.color : 'black',
				WebkitBoxShadow: 'inset 0 0 6px rgba(30,30,30,0.25)',
				fontFamily: 'Nunito',
				textAlign: props.textAlign ? props.textAlign : 'left',
				step: props.step ? props.step : '',
				min: props.min ? props.min : '',
				max: props.max ? props.max : '',
			}}
			maxLength={props.maxlength}
		/>
	)
}

export { BaseInput }