const localeOptions = [
	{ id: "en", name: "English - LTR", direction: "ltr" },
	{ id: "es", name: "Español", direction: "ltr" },
	{ id: "tr", name: "Türkçe", direction: "ltr" },
	{ id: "enrtl", name: "English - RTL", direction: "rtl" }
]

const ThemeColors = () => {
	// let rootStyle = getComputedStyle(document.body);
	return {
	   themeColor1 : '#922c88',
	   themeColor2 : '#4556ac',
	   themeColor3 : '#af67a4',
	   themeColor4 : '#743c6e',
	   themeColor5 : '#4b5480',
	   themeColor6 : '#795d75',
	   themeColor7 : '#c0c0c0',
	   // themeColor1_10 : rootStyle.getPropertyValue("--theme-color-1-10").trim(),
   }
}

export {
	localeOptions,
	ThemeColors,
}
