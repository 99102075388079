import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'
import { H1 } from '../../../components'

const CounterObject = styled.div`margin-top: 10px;`

function AnswerCounter(props) {
    const [percentage, setPercentage] = useState()
    const [answerCount, setAnswerCount] = useState()
    const [playerCount, setPlayerCount] = useState(0)

    useEffect(() => {
        let answersCollected = 0
        const playerKeys = props.game.players ? Object.keys(props.game.players) : []
        setPlayerCount(playerKeys.length)
        
        for (let i = 0; i < playerKeys.length; i++) {
            if (props.game.players[playerKeys[i]].answers && props.game.players[playerKeys[i]].answers[props.game.quiz.questions[props.game.quiz.currentQuestion].id]) {
                answersCollected++
            }
        }

        setAnswerCount(answersCollected)
        setPercentage(parseFloat((answersCollected / playerKeys.length) * 100))

    }, [props.game.players])
        
    return (
        <CounterObject>
            <LinearProgress variant="determinate" value={percentage}
                style={{
                    width: '100%',
                    bottom: '0px',
                }}
            />
            <H1>Cevap Sayısı: {answerCount} / {playerCount}</H1>
        </CounterObject>
    )
}

export default AnswerCounter
