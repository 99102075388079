import React from 'react'
import Grid from '@material-ui/core/Grid'
import AnswerOption from './AnswerOption'

function AnswerOptionList(props) {
	return (
		<Grid container className={props.containerClassName ? props.containerClassName : 'align-bottom' }>
		{
			props.question.answers && props.question.answers.map((answer, index) => (
				<Grid key={index} item xs={props.question.aType === 'multiple-image' ? 6 : 12} sm={6}>
					<AnswerOption answer={answer} aType={props.question.aType} index={index}
						answerQuestion={(a) => { if(props.answerQuestion) { props.answerQuestion(a)} } }
					/>
				</Grid>
			))
		}
		</Grid>
	)
}

export default AnswerOptionList
