import { fire } from './firebaseSettings'
import { generateGameId } from './appUtil'

function fetchGameTemplate(gameTemplateKey, _callback) {
	fire.database().ref('game_templates').orderByChild('templateKey').equalTo(gameTemplateKey)
		.once('value', _callback)
}

function getGameTemplateData(gameTemplateKey, _callback) {
	fetchGameTemplate(gameTemplateKey, (snapshot) => {
		let gameTemplate
		if (snapshot.val()) {			
			snapshot.forEach((child) => {
				gameTemplate = child.val()
			})
		}
		return _callback(gameTemplate)
	})
}

function fetchMyGameTemplates(ownerEmail, _callback) {
	if (ownerEmail === 'yesilokulcom@gmail.com') {
		fire.database().ref('game_templates')
		.once('value', _callback)
	} else {
		fire.database().ref('game_templates').orderByChild('owner').equalTo(ownerEmail)
		.once('value', _callback)
	}
}

function getMyGameTemplates(ownerEmail, _callback) {
	fetchMyGameTemplates(ownerEmail, (snapshot) => {
		let myGameList = []
		if (snapshot.val()) {			
			snapshot.forEach((child) => {
				myGameList.push(child.val())
			})
		}
		return _callback(myGameList)
	})
}



function getGameTemplateRef(gameKey) {
	let gameRef = fire.database().ref(`game_templates/${gameKey}`)
	return gameRef
}

function addGameTemplateAndReturnRef() {
	let gameRef = fire.database().ref(`/game_templates`).push()
	return gameRef
}

function updateGameTemplateData(gameKey, gameUpdatedData, _callback) {
	fire.database().ref(`game_templates/${gameKey}`).update(gameUpdatedData, (error) => {
		_callback(error)
	})
}

function updateQuestionDataInTemplate(gameKey, questionIndex, questionUpdatedData, _callback) {
	fire.database().ref(`game_templates/${gameKey}/quiz/questions/${questionIndex}`).update(questionUpdatedData, (error) => {
		_callback(error)
	})
}

function updateQuestionListInQuizInTemplate(gameKey, newQuestionList, _callback) {
	fire.database().ref(`game_templates/${gameKey}/quiz/questions`).set(newQuestionList, (error) => {
		_callback(error)
	})
}




function addGameInstanceAndReturnRef() { // Create instance from a template
	let gameRef = fire.database().ref(`/game_instances`).push()
	return gameRef
}
function updateGameInstanceData(gameKey, gameUpdatedData, _callback) { // Update instance data for first creation
	fire.database().ref(`game_instances/${gameKey}`).update(gameUpdatedData, (error) => {
		_callback(error)
	})
}

const createNewGameInstance = (gameTemplateKey, newInstanceName, _callback) => {
	getGameTemplateData(gameTemplateKey, (gameTemplate) => {
		console.log('gameTemplate: ', gameTemplate)
		let gameRef = addGameInstanceAndReturnRef()		
		let tempGame = { ...gameTemplate }
		tempGame.players = []
		tempGame.key = gameRef.key
		tempGame.title = newInstanceName
		tempGame.templateKey = gameTemplate.templateKey
		tempGame.gameId = generateGameId()
	
		updateGameInstanceData(tempGame.key, tempGame, (error) => {
			_callback(error, tempGame.gameId)
		})
	})
}

function deleteGameInstance(gameInstanceKey, _callback) {
	let gameInstanceRef = fire.database().ref(`/game_instances/${gameInstanceKey}`)
	gameInstanceRef.remove()
		.then(function() {
			_callback(null)
		})
		.catch(function(error) {
			_callback(error)
		})
}

// function deleteImageFromInstance(gameInstanceKey, fileName) {
// 	let fileRef = 
// }

function fetchGameInstancesForTemplate(gameTemplateKey, _callback) {
	fire.database().ref('game_instances').orderByChild('templateKey').equalTo(gameTemplateKey)
		.once('value', _callback)
}

function getGameInstancesForTemplate(gameTemplateKey, _callback) {
	fetchGameInstancesForTemplate(gameTemplateKey, (snapshot) => {
		let gameInstanceList = []
		if (snapshot.val()) {			
			snapshot.forEach((child) => {
				gameInstanceList.push(child.val())
			})
		}
		return _callback(gameInstanceList)
	})
}


export { getGameTemplateData, getMyGameTemplates, getGameInstancesForTemplate, getGameTemplateRef, addGameTemplateAndReturnRef, updateGameTemplateData, updateQuestionDataInTemplate, updateQuestionListInQuizInTemplate,
	createNewGameInstance, deleteGameInstance, 
	// deleteImageFromTemplate
}
