import React, { useState, useEffect, useContext } from 'react'
import Quiz from '../PageBlocks/quiz/host/Quiz'
import { getGameInstanceData, getGameInstanceRef, updateGameInstanceData } from '../utils/firebaseGameHostAndPlayUtils'
import { createNewGameInstance } from '../utils/firebaseGameCreationUtils'
import { Layout2 } from '../components'
import { AuthContext } from '../data/context/AuthProvider'

function Host(props) {
	const [authData] = useContext(AuthContext)

	const [game, setGame] = useState({})
	const [gameId] = useState((props && props.match && props.match.params && props.match.params.pincode) ? props.match.params.pincode :  '')
	
	useEffect(() => {
		if (gameId && authData.email) {
			joinGame(gameId)
		}
	}, [gameId, authData.email])

	const updateGame = (gameupdate) => {
		updateGameInstanceData(game.key, gameupdate, (error) => {
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
			}
		})
    }
	
	const createGameInstance = () => {
		createNewGameInstance(game.templateKey, 'Copy of ' + game.title, (error, newGameInstanceId) => {
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
			} else {
				document.location.href = '/host-game/' + newGameInstanceId
			}
		})
    }

    const quitGame = () => {
		updateGame({ phase: null }) // TODO: Why to reverse back? For new users? All players and points and answers saved and return back
    }

    const endGame = () => {
      	updateGame({ phase: 'final_result' })
    }

    const joinGame = (gameId) => {
		getGameInstanceData(gameId, (gameData) => {		
			if (gameData) {
				if ((authData.roles && authData.roles.some(role => ['teacher'].indexOf(role) >= 0) && gameData.owner === authData.email) || // system teacher and game owner can edit
					(authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0) ) || // system owner can edit
					(authData.roles && authData.roles.some(role => ['editor'].indexOf(role) >= 0) && gameData.editors && gameData.editors.indexOf(authData.email) >= 0 ) // system editor and game editor can edit
				) {
				// if (gameData.password === password) {
					initGameListener(gameData)
				} else {
					const snack = { variant: 'error', message: 'Could not find matching game' }
					props.showSnackbar(snack)
				}
			} else {
				const snack = { variant: 'info', message: 'No game found' }
				props.showSnackbar(snack)
			}
		})
    }

    const initGameListener = (_game) => {
		let gameRef = getGameInstanceRef(_game.key)
		
		gameRef.on('value', (snapshot) => {
			const game = snapshot.val()			
			if (!game.phase) { game.phase = 'setup' }
			
			if (game) { setGame(game) }
			else { setGame('') }
		})
	}
	
	const gameFunctions = {
		update: updateGame,
		restart: createGameInstance,
		end: endGame,
		quit: quitGame,
	}

	return (
		<Layout2 withAuth={true}>
		{
			(game.phase) &&
				<div className="page-container host-page">
					{game.gameType === 'quiz' && <Quiz game={game} gameFunc={gameFunctions} />}
				</div>
		}
		</Layout2>
	)
}

export default Host
