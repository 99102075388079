import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { isMobile } from 'react-device-detect'
import Drawer from '@material-ui/core/Drawer'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import AssessmentIcon from '@material-ui/icons/Assessment'
import InfoIcon from '@material-ui/icons/Info'
import QuestionListItem from './QuestionListItem'
import QuestionContent from './QuestionContent'
import AnswerOptionList from './AnswerOptionList'
import LessonInfo from './LessonInfo'
import Leaderboard from './Leaderboard'
import { ConnectedUsers } from './ConnectedUsers'

const DrawerContentArea = styled.div`padding: 10px;  height: 100%; width: 90vw;`
const DrawerBottomLine = styled.div` padding-left: 10px; background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%); color: #ffffff; text-align: center; height: 60px; padding-top: 10px; border-radius: 10px 10px 0 0;
	width: 100%; min-width: 280px;
`

const QuestionListIcon = styled(VideoLabelIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`
const LessonInfoIcon = styled(InfoIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`
const UsersIcon = styled(PeopleOutlineIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`
const ReportIcon = styled(AssessmentIcon)`margin-top: 10px;  border-radius: 10px; padding-left: 5px; padding-right: 5px; cursor: pointer; margin-left: 5px; margin-right: 5px;
	border: solid 1px ${(props) => props.stylecolor ? props.stylecolor : 'green'};  color: ${(props) => props.stylecolor ? props.stylecolor : 'green'};
	:hover {background-color: ${(props) => props.stylecolor ? props.stylecolor : 'green'}; color: white;}
`

const ContentArea = styled(Grid)`height: calc(100vh - 60px); text-align: center; vertical-align: middle; display: table-cell; width: calc(100% - 10px); position: relative; overflow: auto; margin: 5px; border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);	
	@media (max-width: 1016px) {
		height: 84vh; 
	}
`
const QuestionCard = styled.div`margin-bottom: 30px; margin-left: 2px; width: calc(100% - 4px);`
const EmptyItem = styled.div`height: 40px; font-size: 18px; text-align: center; border: solid 1px gainsboro; width: 93%; border-radius: 6px; margin-top: 3px; line-height: 40px;
	-webkit-box-shadow: inset 0 0 6px rgba(30,30,30,0.25);
`
const CloseIcon = styled.div`font-size: 18px; float: left; border: solid 2px white; color: white;  border-radius: 24px; width: 28px; text-align: center; cursor: pointer; font-weight: bold; margin-left: 5px;
	:hover {background-color: red; color: white; border: solid 2px red;}
`

function MainDrawer(props) {
	const [drawerState, setDrawerState] = useState('closed')
	const [firstLoad, setFirstLoad] = useState(true)
	// const [drawerQuestionList, setDrawerQuestionList] = useState(props.game.uiState.drawerQuestionList ? props.game.uiState.drawerQuestionList : 'closed')
	// const [drawerLessonInfo, setDrawerLessonInfo] = useState(props.game.uiState.drawerLessonInfo ? props.game.uiState.drawerLessonInfo : 'closed')
	// const [drawerUserList, setDrawerUserList] = useState(props.game.uiState.drawerUserList ? props.game.uiState.drawerUserList : 'closed')
	// const [drawerReport, setDrawerReport] = useState(props.game.uiState.drawerReport ? props.game.uiState.drawerReport : 'closed')

	const [activeQuestion, setActiveQuestion] = useState()

	useEffect(() => {
		if (props.onStateChange) {
			props.onStateChange(drawerState)
		}
	}, [drawerState])

	useEffect(() => {
		if (props.onActiveQuestionChange) {
			console.log('activeQuestion in effect drawer;', activeQuestion)
			props.onActiveQuestionChange(activeQuestion)
		}
	}, [activeQuestion])

	useEffect(() => {
		if (props.mode !== 'active' && props.content === 'question-list' && props.gameInstance.uiState.drawerQuestionList) {
			console.log('props.gameInstance.uiState.drawerActiveQuestionId:', props.gameInstance.uiState.drawerActiveQuestionId)
			console.log('props.gameInstance.quiz.questions[props.gameInstance.uiState.drawerActiveQuestionId]:', props.gameInstance.quiz.questions[props.gameInstance.uiState.drawerActiveQuestionId-1])
			setActiveQuestion(props.gameInstance.quiz.questions[props.gameInstance.uiState.drawerActiveQuestionId-1]) 
		}
	}, [props.gameInstance.uiState.drawerActiveQuestionId])

	useEffect(() => {
		if (firstLoad) {
			setFirstLoad(false)
			setDrawerState('closed')
		} else {
			if (props.content === 'question-list' && props.gameInstance.uiState.drawerQuestionList) { setDrawerState(props.gameInstance.uiState.drawerQuestionList) }
			if (props.content === 'lesson-info' && props.gameInstance.uiState.drawerLessonInfo) { setDrawerState(props.gameInstance.uiState.drawerLessonInfo) }
			if (props.content === 'user-list' && props.gameInstance.uiState.drawerUserList) { setDrawerState(props.gameInstance.uiState.drawerUserList) }
			if (props.content === 'report' && props.gameInstance.uiState.drawerReport) { setDrawerState(props.gameInstance.uiState.drawerReport) }
		}
	}, [props.gameInstance.uiState.drawerQuestionList, props.gameInstance.uiState.drawerLessonInfo, props.gameInstance.uiState.drawerUserList, props.gameInstance.uiState.drawerReport])

	return (
		<div>
			<div style={{float: props.float}}>
				{props.content === 'question-list' && props.showIcon && <QuestionListIcon onClick={() => setDrawerState('open')}  style={{fontSize: isMobile ?  '28px' : '40px'}} stylecolor={props.iconColor} />}
				{props.content === 'lesson-info' && props.showIcon && <LessonInfoIcon onClick={() => setDrawerState('open')}  style={{fontSize: isMobile ?  '28px' : '40px'}} stylecolor={props.iconColor} />}
				{props.content === 'user-list' && props.showIcon && <UsersIcon onClick={() => setDrawerState('open')}  style={{fontSize: isMobile ?  '28px' : '40px'}} stylecolor={props.iconColor} />}
				{props.content === 'report' && props.showIcon && <ReportIcon onClick={() => setDrawerState('open')}  style={{fontSize: isMobile ?  '28px' : '40px'}} stylecolor={props.iconColor} />}
			</div>

			<Drawer anchor="right" open={drawerState === 'open' ? true : false} onClose={() => { if (props.mode === 'active') { setDrawerState('closed') } } } >
				<DrawerContentArea>					
										
						
					{props.content === 'question-list' &&
						<Grid container>						
							<Grid item xs={6} sm={3} style={{ overflow: 'auto', height: 'calc(100vh - 60px)'}} >
								{
									props.gameInstance.quiz.questions.map((question, index) => question &&
										<QuestionCard key={question.id}>
											{
												props.gameInstance.quiz.currentQuestion >= index ?
													<QuestionListItem question={question} transitionDelay={question.id} activeQuestion={activeQuestion}
														selectQuestion={(q) => { if (props.mode === 'active') {setActiveQuestion(q)} } }
														showAnswers={true}
														showMeta={false}
														showDelete={false}
													/>
												:
													<EmptyItem>{index + 1}</EmptyItem>
											}
										</QuestionCard>
									)
								}
							</Grid>
							<ContentArea item xs={6} sm={9} style={{border: 'solid 1px gray'}}>
								{
									activeQuestion && (
										<div>
											<QuestionContent question={activeQuestion} mode={props.mode} />
											<AnswerOptionList question={activeQuestion} />
										</div>
									)
								}
							</ContentArea>						
						</Grid>
					}
					{props.content === 'lesson-info' && <LessonInfo gameInstance={props.gameInstance} />}
					{props.content === 'user-list' && <ConnectedUsers game={props.gameInstance} animate={false} />}
					{props.content === 'report' && <Leaderboard game={props.gameInstance} listCount="250" tableHeight="calc(100vh - 110px)" selectTab={(newValue) => {if (props.selectTab) { props.selectTab(newValue) } }} />}
											
					
				</DrawerContentArea>
				<DrawerBottomLine>
					<Grid item xs={12}>
						{props.content === 'question-list' && <span>DERS - ONIZLEME</span>}
						{props.content === 'lesson-info' && <span>DERS HAKKINDA</span>}
						{props.content === 'user-list' && <span>KATILIMCILAR</span>}
						{props.content === 'report' && <span>PUAN SIRALAMASI</span>}
						
						{props.mode === 'active' && <CloseIcon onClick={() => setDrawerState('closed') }>X</CloseIcon>}
					</Grid>					
				</DrawerBottomLine>
			</Drawer>			
		</div>
	)
}

export default MainDrawer
