import React from 'react'
import styled from 'styled-components'
import { CountdownAnimation } from '../../../components'

const NextQuestionMessage = styled.div`font-size: 60px; margin-top: 80px; font-weight: bold; width: 100%; text-align: center; margin-bottom: 60px;`

function PhaseAwaitingQuestion(props) {
    return (
        <div>
            <NextQuestionMessage>Sıradaki soru geliyor...</NextQuestionMessage>
            <CountdownAnimation speed="slow" />
        </div>
    )
}

export default PhaseAwaitingQuestion
