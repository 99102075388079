import React, { useState, useEffect } from 'react'
import { firestore } from '../../utils/firebaseSettings'

const AuthContext = React.createContext([{}, () => {}])

const AuthProvider = (props) => {
	const [state, setState] = useState({})

	useEffect(() => {
		if (!state.email) {
			if (localStorage.getItem('email')) {
				let email = localStorage.getItem('email')
				let userData = {}
				let userStateData = {}
				
				firestore.collection('users').where("email", "==", email).get().then(snapshot1 => {
					if (snapshot1.docs.length === 1) {
						userData = snapshot1.docs[0].data()
						userStateData = {
							email: email,
							name: userData.name ? userData.name : '',
							roles: userData.roles ? userData.roles : [],
							lessons: userData.lessons ? userData.lessons : [],
							status: userData.status ? userData.status : 'disabled',
						}
						setState(userStateData)
					} else {
						userStateData = {
							email: email,
							name: '',
							roles: [],
							lessons: [],
							status: 'disabled',
						}
						setState(userStateData)
					}					
				})
			}
		}
	}, [])

	return (
		<AuthContext.Provider value={[state, setState]}>
			{props.children}
		</AuthContext.Provider>
	)
}

export { AuthContext, AuthProvider }
