import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { H1 } from '../../../components'
import { getPlayerConnectionURL } from '../../../utils/appUtil'

const PinCodeArea = styled.div`float: right; height: 75px; margin-right: 5px; margin-top: 5px; font-size: 30px;
	@media (max-width: 767px) {
		font-size: 14px;
		height: 40px;
	}
`
const CopyLink = styled.b`font-size: 12px; cursor: pointer; color: blue; text-decoration: underline;`
const PlayURL = styled(H1)`margin-right: 10px; margin-top: 10px;
	@media (max-width: 767px) {
		margin-top: 0px; margin-right: 5px; font-size: 18px;
	}
`

const PinCode = styled.span`margin: 5px; color: red; font-weight: bold;`
function LessonInfo(props) {
	let playURL = getPlayerConnectionURL(props.gameInstance.gameId)

	return (
		<Grid container>
			<Grid item xs={12}>
				<PinCodeArea>
					PIN KODU: <PinCode>{props.gameInstance.gameId}</PinCode>
				</PinCodeArea>
			</Grid>
			<Grid item xs={12} style={{textAlign: 'center'}}>
				<PlayURL>
					<a href={playURL} target="_blank" rel="noopener noreferrer">{playURL}</a>
				</PlayURL>
				<CopyLink onClick={() => navigator.clipboard.writeText(playURL) } style={{fontSize: '12px'}}>(Copy)</CopyLink>				
			</Grid>
			<Grid item xs={12}>
				<b>Soru Sayısı:</b> {props.gameInstance.quiz.questions.length}
			</Grid>
			<Grid item xs={12}>
				<b>Katılımcı Sayısı:</b> {Object.keys(props.gameInstance.players).length}
			</Grid>
		</Grid>
	)
}

export default LessonInfo
