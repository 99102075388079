import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import CreateQuiz from '../PageBlocks/quiz/create/CreateQuiz'
import GameTemplateListItem from '../PageBlocks/quiz/create/GameTemplateListItem'
import { getGameTemplateData, getMyGameTemplates, getGameTemplateRef, addGameTemplateAndReturnRef, updateGameTemplateData } from '../utils/firebaseGameCreationUtils'
import { Layout2, FormLine, BaseButton } from '../components'
import { AuthContext } from '../data/context/AuthProvider'

const CreatePage = styled.div`margin: 10px; width: 100%; margin-left: auto; margin-right: auto; padding: 5px;`
const SubmitButton = styled(BaseButton)`float: right; width: 200px;`

function Create(props) {
	const [authData] = useContext(AuthContext)
	let history = useHistory()

	const [myGames, setMyGames] = useState([])
	const [gameKey, setGameKey] = useState((props && props.match && props.match.params && props.match.params.pincode) ? props.match.params.pincode :  null)
	const [game, setGame] = useState({})

	useEffect(() => {
		if (gameKey && authData.email) {
			joinGame(gameKey)
		}
	}, [gameKey, authData.email])

	useEffect(() => {
		if (authData && authData.email) {
			getMyGameTemplates(authData.email, (returnedGameList) => {
				returnedGameList.sort(function(a,b) {
					if (a.created < b.created) return 1
					if (a.created > b.created) return -1
					return 0
				})
				setMyGames(returnedGameList)
			})			
		}
	}, [authData.email])

	const joinGame = (_gameKey) => {		
		getGameTemplateData(_gameKey, (gameData) => {		
			if (gameData) {
				if ((authData.roles && authData.roles.some(role => ['teacher'].indexOf(role) >= 0) && gameData.owner === authData.email) || // system teacher and game owner can edit
					(authData.roles && authData.roles.some(role => ['owner'].indexOf(role) >= 0) ) || // system owner can edit
					(authData.roles && authData.roles.some(role => ['editor'].indexOf(role) >= 0) && gameData.editors && gameData.editors.indexOf(authData.email) >= 0 ) // system editor and game editor can edit
				) {
					initGameListener(gameData)
				} else {
					const snack = { variant: 'error', message: 'Could not find matching game' }
					props.showSnackbar(snack)
				}
			} else {
				const snack = { variant: 'info', message: 'No game found' }
				props.showSnackbar(snack)
			}
		})
	}
	
	
	
	const initGameListener = (_game) => {
		let gameRef = getGameTemplateRef(_game.key)
		
		gameRef.on('value', (snapshot) => {
			const game = snapshot.val()			
			if (!game.phase) { game.phase = 'setup' }
			
			if (game) { setGame(game) }
			else { setGame('') }
		})
	}

    const createGame = () => {
		const tempGame = {}

		// tempGame.gameId = generateGameId()
		tempGame.created = Date.now()
		tempGame.status = 'CREATED'
		tempGame.phase = 'setup'

		tempGame.title = ''
		tempGame.password = ''
		tempGame.gameType = 'quiz'
		tempGame.owner = authData.email
		tempGame.quiz =  {
			gameMode: 'normal',
			timeLimit: true,
			timeScore: false,
			questions: [{
				id: 0,
				question: '',
				questionImageURL: '',
				questionVideoURL: '',
				startSecond: 0,
				endSecond: 1000000,			
				correctAnswers: [''],
				wrongAnswers: ['', '', ''],
				timeLimit: 10,
				point: 1000,
				qType: 'image',
				aType: 'multiple',
			}]
		}

		let gameRef = addGameTemplateAndReturnRef()		

		tempGame.key = gameRef.key
		tempGame.templateKey = gameRef.key
		setGameKey(tempGame.key)
		setGame(tempGame)

		updateGameTemplateData(tempGame.key, tempGame, (error) => {
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
			}
		})

		history.push('/create-new-game/' + tempGame.key)
		//joinGame(tempGame.gameId)
    }
	return (
		<Layout2 withAuth={true}>
			{
				(!game || !game.key) && (
					<CreatePage>
						{
							authData.roles && authData.roles.some(role => ['owner', 'admin', 'teacher'].indexOf(role) >=0 ) && (
								<FormLine>
									<SubmitButton type="button" onClick={() => createGame()} value="YENİ OLUŞTUR" />
								</FormLine>
							)
						}
						<Grid container>
						{
							myGames && myGames.length > 0 &&
								myGames.map((myGame) => myGame &&
									<Grid item xs={12} sm={6} md={4} key={myGame.key}>
										<GameTemplateListItem gameTemplate={myGame} />
									</Grid>									
								)
						}
						</Grid>
					</CreatePage>
				)
			}

			{
				game && game.key && 
					<div>
						<CreateQuiz showSnackbar={props.showSnackbar} game={game} />
					</div>
			}
		</Layout2>
	)
}

export default Create
