import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import QuestionList from './QuestionList'
import LessonList from './LessonList'
import QuestionForm from './QuestionForm'
import LessonDetails from './LessonDetails'
import { updateGameTemplateData } from '../../../utils/firebaseGameCreationUtils'
import { FormElementLabel, BaseInput, BaseButton, Toggle, DrawerBottomLine, CloseIcon } from '../../../components'

const tabs = [
	{ id: 'design', label: 'Tasarım' },
	{ id: 'lessons', label: 'Dersler' },
]

function CreateQuiz(props) {
	const [gameKey, setGameKey] = useState('')
	const [title, setTitle] = useState('')
	const [gameType, setGameType] = useState('quiz')
	const [timeLimit, setTimeLimit] = useState(true)

	const [activeQuestion, setActiveQuestion] = useState()
	const [questions] = useState([])

	const [drawerId, setDrawerId] = useState('')
	const [drawerState, setDrawerState] = useState(false)
	
	const [selectedInstance, setSelectedInstance] = useState()
	const [tab, setTab] = useState('design')

	useEffect(() => {
		if (props.game) {
			setGameKey(props.game.key)
			setTitle(props.game.title)
			setGameType(props.game.gameType)
			
			setTimeLimit(props.game.quiz.timeLimit)
			if (props.game.quiz.questions && props.game.quiz.questions.length > 0) {
				if (activeQuestion) {
					let activeQuestionID = activeQuestion ? (activeQuestion.id === 0 ? 0 : activeQuestion.id - 1) : 0
					setActiveQuestion(props.game.quiz.questions[activeQuestionID])
				}
			}
		}
	}, [props.game])

	useEffect(() => {
		if (title.length > 0) {
			updateGame()
		}
	}, [title])
	
	const updateGame = () => {		
		const game = {
			title: title,
			gameType,
		}

		updateGameTemplateData(gameKey, game, (error) => {
			if (error) {
				const snack = { variant: 'error', message: 'Unexpected internal error' }
				props.showSnackbar(snack)
			}
		})
	}
	
	const selectDrawer = (_drawerId) => {
		setDrawerId(_drawerId)
		setDrawerState(true)
	}

	return (
		<div>
			<Grid container>
				<Grid item xs={12} style={{borderBottom: 'solid 1px gainsboro', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginTop: '20px', marginBottom: '5px'}}>
					<Grid container>
						<Grid item xs={12} md={1}>
							<FormElementLabel style={{lineHeight: '34px', marginLeft: '5px'}}>Ders Adı</FormElementLabel>
						</Grid>
						<Grid item xs={12} md={6}>
							<BaseInput value={title} maxlength="80" onChange={(newValue) => setTitle(newValue) } />
						</Grid>
						<Grid item xs={12} md={2}></Grid>
						<Grid item xs={12} md={3}>
							<Toggle options={tabs} onChange={(newValue) => setTab(newValue) } value={tab} />
						</Grid>
					</Grid>
				</Grid>

				{
					tab === 'design' &&
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12} md={2} style={{ height: isMobile ? '60px' : 'calc(100vh - 150px)', overflow: 'auto', borderRight: 'solid 1px gainsboro'}}>
									<MobileView>
										<BaseButton onClick={() => selectDrawer('slide-list')} value="Sayfalar" type="button" style={{width: '100px'}} />
									</MobileView>
									<BrowserView>
										<QuestionList game={props.game} setActive={(q) => { setActiveQuestion(q); }}  activeQuestion={activeQuestion} />
									</BrowserView>
									<MobileView>
										<Drawer anchor="left" open={drawerState} onClose={() => setDrawerState(false)}>
											<Grid container>
												{
													drawerId === 'slide-list' && (
														<div>
															<QuestionList game={props.game} setActive={(q) => setActiveQuestion(q)}  activeQuestion={activeQuestion} />
															<DrawerBottomLine style={{width: '100%'}}>
																<Grid item xs={12}>
																	<span>QUESTIONS</span>
																	<CloseIcon onClick={() => setDrawerState(false)}>X</CloseIcon>
																</Grid>
															</DrawerBottomLine>
														</div>
													)
												}												
											</Grid>
										</Drawer>
									</MobileView>
								</Grid>
								<Grid item xs={12} md={1}></Grid>
								<Grid item xs={12} md={8}>
									{
										activeQuestion &&
											<QuestionForm isTimeLimit={!!timeLimit} showSnackbar={props.showSnackbar} questions={questions} activeQuestion={activeQuestion} gameKey={gameKey} />
									}						
								</Grid>
								<Grid item xs={12} md={1}></Grid>
							</Grid>
						</Grid>
				}
				
				{
					tab === 'lessons' &&
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12} md={2} style={{ height: isMobile ? '60px' : 'calc(100vh - 150px)', overflow: 'auto', borderRight: 'solid 1px gainsboro'}}>
									<MobileView>
										<BaseButton onClick={() => selectDrawer('lesson-list')} value="Dersler" type="button" style={{width: '100px'}} />
									</MobileView>
									<BrowserView>
										<LessonList game={props.game} selectedInstance={selectedInstance} setActiveLesson={(l) =>  setSelectedInstance(l)} />
									</BrowserView>
									<MobileView>
										<Drawer anchor="left" open={drawerState} onClose={() => setDrawerState(false)}>
											<Grid container>
												{
													drawerId === 'lesson-list' && (
														<LessonList game={props.game} selectedInstance={selectedInstance} setActiveLesson={(l) =>  setSelectedInstance(l)} />
													)
												}
												
												<DrawerBottomLine style={{width: '100%'}}>
													<Grid item xs={12}>
														<span>LESSONS</span>
														<CloseIcon onClick={() => setDrawerState(false)}>X</CloseIcon>
													</Grid>
												</DrawerBottomLine>											
											</Grid>
										</Drawer>
									</MobileView>
								</Grid>
								<Grid item xs={12} md={1}></Grid>
								<Grid item xs={12} md={8}>
									{
										selectedInstance &&
											<div>
												<LessonDetails gameInstance={selectedInstance} />
											</div>
									}
								</Grid>
								<Grid item xs={12} md={1}></Grid>
							</Grid>
						</Grid>
				}
			</Grid>
		</div>
	)
}

export default CreateQuiz
