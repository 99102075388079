import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import QuestionContent from '../shared/QuestionContent'
import { BaseButton } from '../../../components'

const QuestionSection = styled.div`height: 88vh; text-align: center; overflow: auto; vertical-align: middle; display: table-cell; width: 100vw;
	@media (max-width: 1016px) {
		height: 84vh; 
	}
`
const NextButtonArea = styled.div`margin-right: 10px; margin-bottom: 25px;`
const NextPhaseButton = styled(BaseButton)` width: 250px; position: absolute; bottom: 5px; height: 60px; float: right; right: 5px;
	@media (max-width: 767px) {
		width: 150px;
		height: 40px;
	}
`

function PhaseShowQuestion(props) {
	const [question, setQuestion] = useState('')

	useEffect(() => {
		const tempQuestion = props.game.quiz.questions[props.game.quiz.currentQuestion]
		if (tempQuestion) {
			setQuestion(tempQuestion)
			if (tempQuestion.qType !== 'video') {
				const phaseTime = 3000 + (tempQuestion.question.length * 20)
				console.log('phaseTime: ', phaseTime)
				// setTimeout(nextPhase, phaseTime)
			}
		}
	}, [props.game.quiz.currentQuestion])

	const nextPhase = () => {
		let answers = []
		
		if (question.aType === 'multiple') {
			answers = question.wrongAnswers.concat(question.correctAnswers)
			answers = shuffle(answers)
		} else if (question.aType === 'multiple-image') {
			question.correctAnswersImages.map((answer)=> {
				answers.push(answer.url)
				return ''
			})
			question.wrongAnswersImages.map((answer)=> {
				answers.push(answer.url)
				return ''
			})
			answers = shuffle(answers)
		} else if (question.aType === 'boolean') {
			answers = ['True', 'False']
		}

		if (answers.length) {
			const game = {}
			game.phase = 'answer'
			game.quiz = props.game.quiz
			game.quiz.questions[game.quiz.currentQuestion].answers = answers
			props.gameFunc.update(game)
		} else {
			props.gameFunc.update({ phase: 'answer' })
		}
	}

	const shuffle = (array) => {
		let counter = array.length
		
		while (counter > 0) {
			const index = Math.floor(Math.random() * counter)
			counter--
			const temp = array[counter]
			array[counter] = array[index]
			array[index] = temp
		}
		return array
	}

	return (
		<div>
			{ question &&
				<QuestionSection>
					<QuestionContent question={question} 
						imageBrushColor="red"
						videoPlaying={true} videoControlType="host" videoUseMaxScreen={true} 
						//onVideoEnd={() => nextPhase()}
						onVideoEnd={null}
						gameFunc={props.gameFunc}
						gameKey={props.game.key}
						gamePhase={props.game.phase}
					/>
				</QuestionSection>
			}
			
			<NextButtonArea>				
				<NextPhaseButton onClick={() => nextPhase()} value="DEVAM" type="button" fontSize="24px" />
			</NextButtonArea>
		</div>
	)
}

export default PhaseShowQuestion
