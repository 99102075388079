import React from 'react'
import styled from 'styled-components'
import QuestionVideo from './QuestionVideo'
import QuestionImage from './QuestionImage'
import { isMobile } from 'react-device-detect'

const ComponentArea = styled.div``
const QuestionText = styled.div`width: 100%; text-align: center; margin-top: 10px; font-size: 64px; color: black;`

function QuestionContent(props) {

	let qfontSize = '18px'
	
	if ( (!props.question.questionImageURL || props.question.questionImageURL.length === 0) && (!props.question.questionVideoURL || props.question.questionVideoURL.length === 0) ) { // If no image and video
		qfontSize = props.question.question.length < 30 ? (isMobile ? '48px' : '64px')
		: (props.question.question.length < 60 ? (isMobile ? '48px' : '64px')
			: (props.question.question.length < 90 ? (isMobile ? '40px' : '48px')
				: (props.question.question.length < 120 ? (isMobile ? '36px' : '42px')
					: (props.question.question.length < 150 ? (isMobile ? '28px' : '36px')
						: '18px'
					)
				)
			)
		)
	} else { // With image or video
		qfontSize = props.question.question.length < 30 ? '48px': (props.question.question.length < 60 ? '36px': (props.question.question.length < 90 ? '24px': (props.question.question.length < 120 ? '18px': '14px' ) ) )
	}
	
	
	
    return (
        <ComponentArea>
			<QuestionText style={{fontSize: qfontSize}}>
				{props.question.question}
			</QuestionText>
			
			{
				(props.question.qType === 'image' || props.question.qType === 'imageContent') && props.question.questionImageURL && props.question.questionImageURL.length > 0 &&
					<QuestionImage question={props.question} gameFunc={props.gameFunc} gameKey={props.gameKey} brushColor={props.imageBrushColor} gamePhase={props.gamePhase} />
			}

			{
				(props.question.qType === 'video' || props.question.qType === 'videoContent') && props.question.questionVideoURL && props.question.questionVideoURL.length > 0 &&									
					<QuestionVideo playing={props.videoPlaying} question={props.question} onEnd={props.onVideoEnd} useMaxScreen={props.videoUseMaxScreen} controlType={props.videoControlType} mode={props.mode} />
			}
		</ComponentArea>
    )
}

export default QuestionContent
