import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { H1, BaseInput, BaseButton } from '../../components'


const NickNameSetArea = styled(Grid)`max-width: 600px; margin: auto; text-align: center; height: 250px;
    border: solid 1px gainsboro; border-radius: 8px; padding: 50px;
    -webkit-box-shadow: inset 0 0 6px rgba(30, 30, 30, 0.25);
    @media (max-width: 767px) {
		padding: 20px;
	}
`
const SubmitButton = styled(BaseButton)`float: right; width: 200px;`
function GuestUserName(props) {
	
	return (
		<NickNameSetArea container>
			<Grid item xs={12}>
				<H1>Misafir kullanıcı adınızı belirleyin.</H1>
			</Grid>
			<Grid item xs={12}>
				<BaseInput value={props.name} onChange={(newValue) => { props.updateName(newValue.replace(/\s{2,}/g, ' ')) }} color="navy" fontSize="48px" textAlign="center" height="60px" maxWidth="600px" width="85%" maxlength="16" />
			</Grid>

			<Grid item xs={12}>
				<SubmitButton type="button" onClick={() => props.createPlayer()} value="BELIRLE VE KATIL" />
			</Grid>								
		</NickNameSetArea>
	)
}

export { GuestUserName }
