import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

const QuestionVideoArea = styled.div`width: 100%; text-align: center; position: relative;`
const VideoPlayer = styled(ReactPlayer)`max-width: 100vw; border-radius: 6px; margin: auto;`
const PlayerButton = styled.div`cursor: pointer; margin-top: 5px; font-weight: bold;`

function QuestionVideo(props) {
	const qPlayer = useRef(0)

	const [videoVolume, setVideoVolume] = useState(50)
	const [playing, setPlaying] = useState()
	const [question, setQuestion] = useState()

	useEffect(() => {
		setPlaying(props.playing)
	}, [props.playing])

	useEffect(() => {
		setQuestion(props.question)
	}, [props.question])

    return (
        <QuestionVideoArea>
			{
				question &&
					<div>
						<VideoPlayer url={question.questionVideoURL} playing={playing} loop={false} volume={videoVolume} ref={qPlayer}
							controls={props.controlType && props.controlType === 'host' ? true : false}
							style={{ 
								pointerEvents: (props.controlType && props.controlType === 'host' ? '' : 'none'),
								height: (props.useMaxScreen ? 'calc(100vh - 300px)': ''),
								maxHeight: (props.useMaxScreen ? 'calc(100vh - 300px)': '320px'),
							}}
							onVolumeChange={(e) => { setVideoVolume(e.target.volume) } }
							onProgress={(e) => {
								if( (question.startSecond && question.startSecond > 0) || (question.endSecond && question.endSecond > 0) ) {
									if (e.playedSeconds > (question.endSecond ? question.endSecond : 100000000 ) || 
										e.playedSeconds < (question.startSecond ? question.startSecond : 0 ) ) {
										setPlaying(false)
										if (props.onEnd) { // Call on end
											props.onEnd()
										} else { // or just rewind
											qPlayer.current.seekTo(question.startSecond, true)
										}
									} 
								} 
							}}
							onReady={(e) => { 
								if( (question.startSecond && question.startSecond > 0)) {
									qPlayer.current.seekTo(question.startSecond, true)
								}
							}}
							onError={(e) => { console.log('onError ', e, ' url:', question.questionVideoURL) } }
						/>
						{
							(!props.controlType || props.controlType !== 'host') && props.mode === 'active' &&
								<PlayerButton onClick={() => setPlaying(!playing)}>{playing ? 'DURDUR' : 'BAŞLAT'}</PlayerButton>
						}
						
					</div>
			}
		</QuestionVideoArea>
    )
}

export default QuestionVideo
