import React  from 'react'
import styled from 'styled-components'
import Podium from '../shared/Podium'
// import Button from '@material-ui/core/Button'
import { H1 } from '../../../components'

const HeaderText = styled(H1)`font-size: 36px; margin-top: 15px;`

function PhaseFinalResult(props) {
    return (
        <div>                
            <HeaderText>SONUÇLAR</HeaderText>
            <Podium game={props.game} />
            

            {/* <div className="align-bottom ">
                <div>
                    <Button onClick={props.gameFunc.restart}>DERSI TEKRARLA</Button>
                </div>
            </div> */}
        </div>
    )
}

export default PhaseFinalResult
